<!--
 * @Description: 上传滑动图片
 * @Author: 前胡
 * @LastEditors: 胡椒
 * @Date: 2020-03-12 17:03:34
 * @LastEditTime: 2020-08-19 09:34:29
 -->
 <template>
  <div>
    <a-radio-group v-model="pict_url" @change="handleChangepict_url">
      <ul class="swiper-images">
        <li v-for="(item,idx) in swiperImageArr" :key="item">
          <div class="pic">
            <img :src="item" />
            <a href="javascript:;" class="rm-trigger" @click="delImg(idx)" v-if="!isReadOnly">
              <a-icon type="delete" />
            </a>
          </div>
          <div class="radio-box">
            <a-radio :value="item" :disabled="isReadOnly">
              <template v-if="!isReadOnly">设为主图</template>
              <template v-else>主图</template>
            </a-radio>
          </div>
        </li>
        <li v-if="swiperImageArr.length < 5 && isReadOnly === false">
          <a-spin tip="正在上传请稍后..." :spinning="uploading">
            <div class="upload-box">
              <div class="upload-tip">
                <p>
                  <a-icon type="plus" style="font-size:40px" />
                </p>
                <p>建议750*750</p>
              </div>
              <input @change="upload" type="file" name="photoImg" class="J-imgUploadFile upload-input" />
            </div>
          </a-spin>
        </li>
      </ul>
    </a-radio-group>
  </div>
</template>
<script>
import axios from 'axios';
import { generateUUID } from "@/utils/index";
import { getOssToken } from "@/service/user";

export default {
  name: "Uploadsmall_images",
  props: {
    small_images: {
      default: function() {
        return [];
      },
      type: Array
    },
    pict_url: {
      default: '',
      type: String
    },
    isReadOnly: {
      default: false,
      type: Boolean
    }
  },
  data() {
    return {
      swiperImageArr: JSON.parse(JSON.stringify(this.small_images)),
      pickededImg: this.pict_url,
      uploading: false
    };
  },
  watch:{
    small_images: function(o){
      this.swiperImageArr = o
    }
  },
  methods: {
    // 获取osstoken
    async getOssToken() {
      const { err, res } = await getOssToken();
      if (!err && res.success) this.oss = res.data;
    },
    // 文件上传方法
    async handleFileUpload(file, imgInfo, e) {
      if (!this.oss) await this.getOssToken();
      if (!this.oss) {
        this.$message.warn("上传失败");
        return false;
      }
      const formData = new FormData();
      const pathArr = file.name.split(".");
      const key = `${this.oss.dir}douyin-goods/${generateUUID()}.${
        pathArr[pathArr.length - 1]
      }`;
      formData.append("key", key);
      formData.append("policy", this.oss.policy);
      formData.append("signature", this.oss.signature);
      formData.append("success_action_status", "200");
      formData.append("OSSAccessKeyId", this.oss.accessid);
      formData.append("file", file);

      this.uploading = true;

      axios({
        method: "post",
        url: this.oss.host,
        data: formData
      })
        .then(response => {
          const { err } = response;
          if (!err) {
            const imagePath = this.oss.host + "/" + key;
            this.swiperImageArr.push(imagePath);
            this.pickededImg = this.pickededImg
              ? this.pickededImg
              : this.swiperImageArr[0];

            this.$emit("callback", {
              small_images: this.swiperImageArr,
              pict_url: this.pickededImg
            });
            this.$message.success(`上传成功`);
            e.target.value = "";
          } else {
            this.$message.error(`上传失败，请稍后重试`);
            e.target.value = "";
          }
          this.uploading = false;
        })
        .catch(error => {
          this.uploading = false;
          this.$message.error(`${error}`);
          this.isUplodSuccess = false;
          e.target.value = "";
        });
    },
    // 切换选中图片
    handleChangepict_url(e) {
      const v = e.target.value;
      this.pickededImg = v;
      this.$emit("callback", {
        small_images: this.swiperImageArr,
        pict_url: this.pickededImg
      });
    },
    // 开始上传前的准备
    async upload(e) {
      const file = e.target.files[0];
      if (file) {
        if (
          ["image/gif", "image/png", "image/jpg", "image/jpeg"].indexOf(
            file.type
          ) > -1
        ) {
          // 获取图片宽高
          const reader = new FileReader();
          reader.onloadend = () => {
            const img = new Image();
            img.onload = () => {
              let invalide = true;
              if (img.width > 1200 || img.height > 1200) {
                this.$message.error("图片的宽度，高度最大不能超过1200px！");
                return false;
              }
              if (img.width !== img.height) {
                this.$message.error("请上传长和宽一样的正方形图片!");
                return false;
              }
              if (invalide) {
                const imgInfo = {
                  width: img.width,
                  height: img.height
                };
                this.handleFileUpload(file, imgInfo, e);
              } else {
                e.target.value = "";
              }
            };
            img.src = reader.result;
          };
          reader.onerror = err => {
            console.log("reader error", err);
          };
          // 读出文件路径
          reader.readAsDataURL(file);
        } else {
          this.$message.error("图片格式须为jpg、jpeg、png、gif之一！");
          return false;
        }
      }
    },
    // 删除图片
    delImg(idx) {
      this.swiperImageArr.splice(idx, 1);
      if (this.swiperImageArr.length) {
        if(this.pickededImg==='' || this.swiperImageArr.indexOf(this.pickededImg)<0){
          this.pickededImg = this.swiperImageArr[0];
        }

      } else {
        this.pickededImg = '';
      }
      this.$emit("callback", {
        small_images: this.swiperImageArr,
        pict_url: this.pickededImg
      });
    }
  }
};
</script>
<style lang="less" scoped>
.swiper-images {
  display: flex;
  li {
    margin-right: 10px;
    .pic {
      position: relative;
      width: 120px;
      height: 120px;
      img {
        width: 120px;
        height: 120px;
      }
      .rm-trigger {
        display: none;
        position: absolute;
        width: 40px;
        height: 40px;
        font-size: 24px;
        z-index: 1;
        right: 0;
        top: 0;
        background: rgba(0, 0, 0, 0.6);
        justify-content: center;
        align-items: center;
      }
      &:hover {
        .rm-trigger {
          display: flex;
        }
      }
    }
    .radio-box {
      text-align: center;
    }
  }
  .upload-box {
    display: block;
    border: 1px dashed #ccc;
    width: 118px;
    height: 118px;
    border-radius: 5px;
    text-align: center;
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      width: 120px;
      height: 120px;
      opacity: 0;
      cursor: pointer;
    }
    .upload-tip {
      display: flex;
      flex-direction: column;
      height: 120px;
      align-content: center;
      justify-content: center;
    }
    &:hover {
      border-color: #e83741;
    }
  }
}
</style>
