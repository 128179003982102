<!--
 * @Description: 定时上下架
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-07-11 14:36:48
 -->
 <template>
  <div class="timing-on-down-modal">
    <a-modal :title="modalTitle" v-model="timingOnDownModalVisible" @cancel="handleHideThisModal">
      <a-form :form="editTimingOnForm">
        <a-form-item v-if="timeType==='up'" :label-col="{ span: 7 }" label="定时上架时间" :wrapper-col="{ span: 12 }">
          <div>
            <a-date-picker
              format="YYYY-MM-DD HH:mm"
              @change="onChangeStartDate"
              :minuteStep="15"
              :showToday="false"
              :showTime="showTimeOption"
              :defaultValue="default_sale_start_time"
              v-decorator="[
          'sale_start_time',
          { rules: [{ required: true, message: '请设置定时上架时间' }] },
        ]"
            />
          </div>
        </a-form-item>
        <a-form-item v-if="timeType==='down'" :label-col="{ span: 7 }" label="定时下架时间" :wrapper-col="{ span: 12 }">
          <div>
            <a-date-picker
              format="YYYY-MM-DD HH:mm"
              @change="onChangeEndDate"
              :minuteStep="15"
              :showToday="false"
              :showTime="showTimeOption"
              :defaultValue="default_sale_end_time"
              v-decorator="[
          'sale_end_time',
          { rules: [{ required: true, message: '请设置定时下架时间' }] },
        ]"
            />
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isPosting"
          @click="handleSaveTimingOnDownData"
        >提交</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import { batchTimingOnSale } from "@/service/goods";
export default {
  name: "BatchTimingOnDownModal",
  props: {
    data: {
      default: function() {
        return {};
      }
    },
    timeType: {
      type: String,
      default: "up"
    },
    batchItems: {
      default: function() {
        return [];
      }
    }
  },
  computed: {
    modalTitle() {
      return this.timeType === "down" ? "定时下架" : "定时上架";
    }
  },
  data() {
    return {
      editTimingOnForm: this.$form.createForm(this),
      timingOnDownModalVisible: true,
      showTimeOption: {
        format: "HH:mm",
        minuteStep: 15,
        defaultValue: moment("00:00", "HH:mm")
      },
      isPosting: false,
      sale_start_time: undefined,
      sale_end_time: undefined,
    };
  },
  methods: {
    moment,
    // 修改定时上架
    onChangeStartDate(date, dateString) {
      this.sale_start_time = dateString;
    },
    // 修改定时下架
    onChangeEndDate(date, dateString) {
      this.sale_end_time = dateString;
    },
    // 提交数据
    handleSaveTimingOnDownData() {
      this.editTimingOnForm.validateFields(async err => {
        if (!err) {
          let data = {
            sale_start_time: this.sale_start_time ? this.sale_start_time : null,
            sale_end_time: this.sale_end_time ? this.sale_end_time : null,
            ids: this.batchItems
          };

          if (!data.sale_start_time && !data.sale_end_time) {
            this.$message.error("请选择时间!");
            return false;
          }
          
          if (this.timeType==='down') {
            data.sale_start_time = undefined;
          }

          if (this.timeType==='up') {
            data.sale_end_time = undefined;
          }

          this.isPosting = true;
          const { res_err, res } = await batchTimingOnSale(data);
          this.isPosting = false;
          // 刷新页面
          this.$emit('refresh')
          if (!res_err) {
            if (res.success) {
              if(res.data.fail_count){
                this.$emit('batchCallback', res.data)
              }else{
                this.$message.success('批量操作成功')
              }
              this.handleHideThisModal(0);
            }
          }
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = -1) {
      this.$emit("callback", isNeedParent);
    }
  }
};
</script>
<style lang="less" scoped>
.user-other-address-modal {
  width: 800px;
}
.address-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .address-item {
    margin-bottom: 10px;
    .ai-name {
      width: 150px;
      display: inline-block;
    }
    .ai-phone {
      width: 120px;
      display: inline-block;
    }
    .ai-address {
    }
  }
}
</style>