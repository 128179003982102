<!--
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-05 16:44:54
 * @LastEditTime: 2020-09-28 15:23:57
 * @Description: 商品管理搜索条
 -->
<template>
  <div class="goods-search-bar">
    <a-form class="filter-condition-form" layout="inline" :form="form" >
      <a-form-item>
        <a-input
          v-model="searchParams.search"
          allowClear
          placeholder="商品名称/商品编码"
        />
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 120px"
          placeholder="上架状态"
          v-model="searchParams.is_on_sale"
          allowClear
        >
          <a-select-option v-for="item in inventoryStatusOptions" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select style="width: 130px" placeholder="寄样模式" v-model="searchParams.is_support_send" allowClear>
          <a-select-option v-for="item in sendSampleOptions" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 130px"
          placeholder="样品回收"
          v-model="searchParams.is_recycle"
          allowClear
        >

          <a-select-option v-for="item in sampleRecycleOptions" :key="item.value">{{item.label}}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <a-select
          style="width: 170px"
          placeholder="所属公司"
          v-model="searchParams.supplier_obj_id"
          :default-active-first-option="false"
          show-search
          :allowClear="true"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearchSupplier"
        >
          <a-select-option v-for="item in supplierList" :key="item.id">{{ item.supplier_name }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item   >
        <a-select
          style="width: 120px"
          placeholder="招商人员"
          v-model="searchParams.merchant_user_profile_username"
          :default-active-first-option="false"
          show-search
          :allowClear="true"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearchpeopleSupplier"
        >
          <a-select-option v-for="item in supplierPeopleList" :key="item.username">{{ item.username }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item>
        <goods-range title="商品销售价" v-model="searchParams.kuran_price" />
      </a-form-item>
      <a-form-item>
        <goods-range title="佣金率" :isneedbaifenhao=true v-model="searchParams.external_commission_rate" />
      </a-form-item>
      <a-form-item>
        <goods-range title="服务费率" :isneedbaifenhao=true v-model="searchParams.campaign_product_service_rate" />
      </a-form-item>
      <a-form-item style="width: 150px">
        <a-input
          v-model="searchParams.item_tag"
          allowClear
          :maxLength="6"
          placeholder="商品标签"
          v-decorator="[
          'searchParams.item_tag',
          { rules: [{
              validator: checkNotspecial,
          }] },
        ]"
        />
      </a-form-item>
      <a-form-item>
          <a-select style="width: 130px" placeholder="商品来源" v-model="searchParams.source" allowClear>
              <a-select-option v-for="item in sourceOptionsnew" :key="item.value">{{item.label}}</a-select-option>
          </a-select>
      </a-form-item>
      <a-form-item class="option-btns">
        <a-button type="primary" html-type="submit"  @click="handleFormSubmit" class="option-btn">查询</a-button>
        <a-button v-auth="'goods_manage_batch_upshelf_goods'" type="primary" class="option-btn" html-type="submit" @click="handleBatchPutawayGoods()">批量上架</a-button>
        <a-button v-auth="'goods_manage_batch_downshelf_goods'" type="primary" class="option-btn" html-type="submit" @click="handleBatchDownGoods()">批量下架</a-button>
      </a-form-item>
    </a-form>
  </div>
</template>
<script>
import { goodsChannelList, sourceOptions, sendSampleTwoOptions } from "@/const/goods-cate.js";
import { supplierList } from '@/service/supplier'
import { getBrands } from '@/service/goods'
import GoodsRange from './goods-range'
import { supplieruserList } from '@/service/supplier';
import {
  checkNotspecial
} from "@/utils/validator";
// 搜索类型
const searchTypeOptions = [
  { label: "商品名称", value: 2 },
  { label: "商品ID", value: 1 }
];

// 库存状态
const inventoryStatusOptions = [
  { label: "上架", value: 1 },
  { label: "下架", value: 2 }
];


// 支持寄样
const isSupportSampleOptions = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
];

// 支持寄样
const sampleRecycleOptions = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
];

// 商品来源
const dataSourceOptions = [
  { label: "自动获取", value: 1 },
  { label: "后台维护", value: 2 }
];
const sourceOptionsnew =[
  { label: "招商系统", value: 5 },
  { label: "精选联盟", value: 6 },
  { label: "团长活动", value: 7 },
  // { label: "批量导入", value: 8 },
 ]



// 时间类型
const timeTypeOptions = [
  { label: "创建时间", value: 1 },
  { label: "上架时间", value: 2 },
  { label: "最后更新", value: 3 }
];

export default {
  name: "GoodsSearchBar",
  components: { GoodsRange },
  props: {
    searchParams: {
      default: function () {
        return {};
      },
      type: Object
    },
    goodsCate: {
      type: Array,
      default: () => []
    },
    loading: {
      default: false,
      type: Boolean
    },
  },
  data() {
    return {
      checkNotspecial,
      form: this.$form.createForm(this),
      searchTypeOptions,
      inventoryStatusOptions,
      isSupportSampleOptions,
      sampleRecycleOptions,
      dataSourceOptions,
      sourceOptions,
      sourceOptionsnew,
      goodsChannelList,
      timeTypeOptions,
      sendSampleOptions: sendSampleTwoOptions,
      supplierList: [],
      supplierPeopleList:[],
      brandList: []
    };
  },
  created() {
    this.handleSearchpeopleSupplier('');
  },
  methods: {
    handleSearchpeopleSupplier(str) {
      supplieruserList({
        search: str
      }).then((res) => {
        if (res.res.success === true) {
          this.supplierPeopleList = res.res.data.results;
        }
      })
    },
    // 申请列表搜素表单submit
    handleFormSubmit(e) {
      e.preventDefault();
      this.form.validateFields(err => {
        if (!err) {
          if (!this.searchParams.time_type) {
            this.searchParams.range_time = [];
            this.searchParams.start_time = undefined;
            this.searchParams.end_time = undefined;
          }
          this.$emit("callback", this.searchParams);
        }
      });
    },
    handleBatchPutawayGoods(){
      this.$emit("handleBatchPutawayGoods");
    },
    handleBatchDownGoods(){
      this.$emit("handleBatchDownGoods");
    },
    // 修改查询时间段
    onChangeRangeTime(e, str) {
      this.searchParams.range_time = str;
      this.searchParams.start_time = str[0];
      this.searchParams.end_time = str[1];
    },
    handleSearchSupplier(str) {
      supplierList({
        supplier_name: str
      }).then((res) => {
        if (res.res.success === true) {
          this.supplierList = res.res.data.results
        }
      })
    },
    handleSearchBrand(str) {
      getBrands({
        name: str,
        page: 1,
        page_size: 20
      }).then((res) => {
        if (res.res.success === true) {
          this.brandList = res.res.data.results
        }
      })
    }
  }
};
</script>
<style lang='less' scoped>
.goods-search-bar {
  margin-bottom: 12px;
}

.option-btns {
  margin-top: 0px !important;
  .option-btn {
    margin-right: 10px;
  }
}
</style>
