var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-form',{attrs:{"form":_vm.douyinForm},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"所属公司","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-input',{attrs:{"disabled":"","value":_vm.postData.supplier}})],1),(_vm.postData.data_source===2)?_c('a-form-item',{attrs:{"label":"商品名称","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.item_name',
        { rules: [{ required: true, message: '请输入商品名称' },{
          max:120,
          message: '最多120个字',
        },{validator: _vm.checkBlank}]  } ]),expression:"[\n        'postData.item_name',\n        { rules: [{ required: true, message: '请输入商品名称' },{\n          max:120,\n          message: '最多120个字',\n        },{validator: checkBlank}]  },\n      ]"}]})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"商品ID","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{attrs:{"disabled":"","value":_vm.postData.item_id}})],1),_c('a-form-item',{attrs:{"label":"商品链接","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('div',{staticStyle:{"position":"relative"}},[_c('a-input',{staticStyle:{"position":"absolute","width":"500px"},attrs:{"disabled":"","value":_vm.postData.item_url}}),_c('img',{staticStyle:{"display":"inline-block","margin-top":"10px","width":"20px","height":"20px","position":"absolute","margin-left":"510px"},attrs:{"src":require("../../../assets/icon-share.png")},on:{"click":function($event){return _vm.gotoNew(_vm.postData.item_url)}}})],1)]),_c('a-form-item',{attrs:{"label":"商品类目","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.merchant_category' ]),expression:"[\n        'postData.merchant_category',\n      ]"}],staticStyle:{"width":"300px"},attrs:{"disabled":""}})],1),_c('a-form-item',{attrs:{"label":"商品品牌","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.brand'
      ]),expression:"[\n        'postData.brand'\n      ]"}],staticStyle:{"width":"300px"},attrs:{"getPopupContainer":function (triggerNode) { return triggerNode.parentElement; },"show-search":"","allowClear":true,"show-arrow":false,"filter-option":_vm.filterBrandOption,"placeholder":"请选择"},on:{"change":_vm.handleBrandChange}},_vm._l((_vm.goodsBrandList),function(item){return _c('a-select-option',{key:item.id},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-item',{attrs:{"label":"划线价","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'postData.final_price',
          {
            rules: _vm.validatorRules.commission_final_price,
          }
        ]),expression:"[\n          'postData.final_price',\n          {\n            rules: validatorRules.commission_final_price,\n          }\n        ]"}],staticStyle:{"width":"400px"},attrs:{"type":"number","min":0.00,"step":0.01,"max":10000000000000,"precision":2},on:{"change":_vm.onChangeFinalPrice}})],1),_c('a-form-item',{attrs:{"label":"销售价","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'postData.kuran_price',
          {
             rules: _vm.validatorRules.commission_kuran_price,
          }
        ]),expression:"[\n          'postData.kuran_price',\n          {\n             rules: validatorRules.commission_kuran_price,\n          }\n        ]"}],staticStyle:{"width":"400px"},attrs:{"type":"number","min":0.00,"step":0.01,"max":10000000000000,"precision":2},on:{"change":_vm.onChangeKuranPrice}})],1),_c('a-form-item',{attrs:{"label":"佣金率","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'postData.external_commission_rate',
          {
            initialValue: _vm.postData.external_commission_rate || undefined,
            rules: _vm.validatorRules.external_commission_rate,
          }
        ]),expression:"[\n          'postData.external_commission_rate',\n          {\n            initialValue: postData.external_commission_rate || undefined,\n            rules: validatorRules.external_commission_rate,\n          }\n        ]"}],staticStyle:{"width":"300px"},attrs:{"min":0.00,"step":0.01,"max":100,"formatter":function (value) { return (value + "%"); },"parser":function (value) { return value.replace('%', ''); },"placeholder":"佣金率","precision":2},on:{"change":_vm.onChangeExternalCommission}})],1),_c('a-form-item',{attrs:{"label":"服务费率","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{attrs:{"disabled":"","value":_vm.postData.campaign_product_service_rate?_vm.postData.campaign_product_service_rate+'%' : ''}})],1),_c('a-form-item',{staticClass:"label-required",attrs:{"label":"商品轮播图","label-col":{ span: 5 },"wrapper-col":{ span: 19 }}},[_c('UploadSwiperImages',{attrs:{"small_images":_vm.postData.slide_images,"pict_url":_vm.postData.pict_url},on:{"callback":_vm.updateSiwperData}})],1),_c('a-form-item',{attrs:{"label":"寄样模式","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-radio-group',{attrs:{"disabled":_vm.isDisabledSend,"options":_vm.postData.is_kuran_selected==1 ? _vm.sendSampleOptions : _vm.sendSampleTwoOptions},on:{"change":_vm.changeIsSupportSend},model:{value:(_vm.postData.is_support_send),callback:function ($$v) {_vm.$set(_vm.postData, "is_support_send", $$v)},expression:"postData.is_support_send"}})],1),(_vm.postData.is_support_send === 1)?_c('a-form-item',{attrs:{"label":"样品回收","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.is_recycle',
        { rules: [{ required: true, message: '请选择是否需要样品回收！' }] } ]),expression:"[\n        'postData.is_recycle',\n        { rules: [{ required: true, message: '请选择是否需要样品回收！' }] },\n        ]"}],attrs:{"options":_vm.isRecycleOptions},on:{"change":_vm.changeIsRecycle}})],1):_vm._e(),(_vm.isRecycleStatus===1 && _vm.postData.is_support_send === 1)?_c('a-form-item',{attrs:{"label":"回寄地址","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('div',[_c('a-form-item',{attrs:{"label":"收货人","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.receiver',
        { rules: [{ required: true, message: '请输入收货人' },{
          max:20,
          message: '最多20个字',
        } ]}
      ]),expression:"[\n        'postData.receiver',\n        { rules: [{ required: true, message: '请输入收货人' },{\n          max:20,\n          message: '最多20个字',\n        } ]}\n      ]"}],staticStyle:{"width":"300px"},attrs:{"max":"20"}})],1),_c('a-form-item',{attrs:{"label":"联系电话","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.receiver_mobile',{ rules: [{ required: true, message: '请输入手机号' },{ validator: _vm.checkPhoneNumber } ]}
      ]),expression:"[\n        'postData.receiver_mobile',{ rules: [{ required: true, message: '请输入手机号' },{ validator: checkPhoneNumber } ]}\n      ]"}],staticStyle:{"width":"300px"}})],1),_c('a-form-item',{attrs:{"label":"收货地址","label-col":{ span: 5 },"wrapper-col":{ span: 18 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.delivery_address',{ rules: [{ required: true, message: '请输入收货地址' },{
          max:100,
          message: '最多100个字',
        } ]}
      ]),expression:"[\n        'postData.delivery_address',{ rules: [{ required: true, message: '请输入收货地址' },{\n          max:100,\n          message: '最多100个字',\n        } ]}\n      ]"}],staticStyle:{"width":"300px"},attrs:{"autoSize":{ minRows: 4, maxRows: 6 }}})],1)],1)]):_vm._e(),_c('a-form-item',{attrs:{"label":"产品利益点","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'postData.benefit',
          {
            initialValue: _vm.postData.benefit,
          } ]),expression:"[\n          'postData.benefit',\n          {\n            initialValue: postData.benefit,\n          },\n        ]"}],attrs:{"placeholder":"请输入","autoSize":{ minRows: 4, maxRows: 12 },"maxLength":1000}})],1),_c('a-form-item',{attrs:{"label":"直播卖点","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'postData.sell_point' ]),expression:"[\n          'postData.sell_point',\n        ]"}],attrs:{"placeholder":"请输入","autoSize":{ minRows: 4, maxRows: 12 },"maxLength":1000}})],1),_c('a-form-item',{attrs:{"label":"招商人员","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-select',{attrs:{"placeholder":"招商人员","default-active-first-option":false,"show-search":"","allowClear":true,"show-arrow":false,"filter-option":false,"not-found-content":null,"getPopupContainer":function (triggerNode) { return triggerNode.parentElement; }},on:{"search":_vm.handleSearchSupplier},model:{value:(_vm.postData.merchant_user_profile_id),callback:function ($$v) {_vm.$set(_vm.postData, "merchant_user_profile_id", $$v)},expression:"postData.merchant_user_profile_id"}},_vm._l((_vm.supplierPeopleList),function(item){return _c('a-select-option',{key:item.id,attrs:{"disabled":item.checked}},[_vm._v(_vm._s(item.username))])}),1)],1),_c('a-form-item',{attrs:{"label":"商品标签1","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.tag1',
        { rules: [{
            validator: _vm.checkNotspecial,
        }] } ]),expression:"[\n        'postData.tag1',\n        { rules: [{\n            validator: checkNotspecial,\n        }] },\n      ]"}],attrs:{"maxLength":6},model:{value:(_vm.postData.tag1),callback:function ($$v) {_vm.$set(_vm.postData, "tag1", $$v)},expression:"postData.tag1"}})],1),_c('a-form-item',{attrs:{"label":"商品标签2","label-col":{ span: 5 },"wrapper-col":{ span: 12 }}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'postData.tag2',
        { rules: [{
            validator: _vm.checkNotspecial,
        }] } ]),expression:"[\n        'postData.tag2',\n        { rules: [{\n            validator: checkNotspecial,\n        }] },\n      ]"}],attrs:{"maxLength":6},model:{value:(_vm.postData.tag2),callback:function ($$v) {_vm.$set(_vm.postData, "tag2", $$v)},expression:"postData.tag2"}})],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 12, offset: 5 }}},[_c('a-button',{staticClass:"mr-20",on:{"click":_vm.handleHideDrawer}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","disabled":_vm.postDisabled,"loading":_vm.isPosttingKrGoods}},[_vm._v("保存 ")])],1)],1),(_vm.isShowSpecPickerModal)?_c('SpecPickerModal',{attrs:{"fillData":_vm.skuData},on:{"callback":_vm.hideSpecPickerModal}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }