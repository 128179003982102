<!--
 * @Description: 批量新增弹层
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-12 17:03:34
 * @LastEditTime: 2020-09-28 15:24:31
 -->
 <template>
  <div>
    <a-modal
      class="batch-upload-goods-dialog"
      title="批量导入商品"
      v-model="batchUploadGoodsDialogVisible"
      centered
      :bodyStyle="{padding:0}"
      :footer="null"
      @cancel="hideImportFile"
    >
      <div class="p-20">
        <a-alert class="mb-10" :message="tip" banner v-if="importStatus ==='init'" />
        <a-form>
          <a-form-item
            label
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 12 }"
            v-if="importStatus ==='init'"
          >
            <a-upload
              name="file"
              accept=".xlsx, .xls"
              :multiple="false"
              :default-file-list="fileList"
              :beforeUpload="beforeUpload"
              :on-remove="onRemove"
            >
              <a-button>
                <a-icon type="upload" />选择xls文件上传
              </a-button>
            </a-upload>
          </a-form-item>
          <a-form-item v-if="importStatus ==='init'">
            <div class="lh-120">
              <p>
                <a :href="goodsImportManualWorkTemplateUrl" target="_blank">下载淘宝-后台维护商品模板</a>
              </p>
              <p>
                <a :href="goodsImportAutoFetchTemplateUrl" target="_blank">下载淘宝-自动获取商品模板</a>
              </p>
              <p>
                <a :href="goodsImportDouyinTemplateUrl" target="_blank">下载抖音小店商品模板</a>
              </p>
            </div>
          </a-form-item>
          <a-form-item v-show="importStatus ==='uploading'">
            <div class="upload-spin">
              <a-spin size="large" />
            </div>
          </a-form-item>
          <a-form-item v-if="importStatus ==='done'">
            <div class="upload-res-box">
              <p>
                本次导入{{importSuccessData.total_goods_num}}条商品，
                创建成功{{importSuccessData.create_num}}条，其中
              </p>
              <p>更新{{importSuccessData.update_num}}条，</p>
              <p>失败{{importSuccessData.failed_num}}条，</p>
              <p>未加入淘宝联盟：{{importSuccessData.not_in_taobao}}条，</p>
              <p>同步失败：{{importSuccessData.sync_failed}}条，</p>
              <!-- <p>重复商品：{{importSuccessData.goods_repeat}}条，</p> -->
              <a :href="importSuccessData.detail_url" target="_blank">下载本次处理结果</a>
            </div>
          </a-form-item>
        </a-form>
      </div>
      <div class="ant-modal-footer">
        <div v-if="importStatus ==='init'">
          <button type="button" class="ant-btn ant-btn-primary" @click="handleUploadGoods">
            <span>上 传</span>
          </button>
        </div>
        <div v-if="importStatus ==='done'">
          <button type="button" class="ant-btn ant-btn-primary" @click="hideImportFile">
            <span>关 闭</span>
          </button>
        </div>
      </div>
    </a-modal>
  </div>
</template>
<script>
import { baseURL } from "@/config/index";
import {
  goodsImportAutoFetchTemplateUrl,
  goodsImportManualWorkTemplateUrl,
  goodsImportDouyinTemplateUrl
} from "@/const/template";
import axios from "@/service/axios";

export default {
  name: "ImportFile",
  props: {
    tip: {
      default:
        "会自动从淘宝联盟同步并创建商品，同步失败的商品不会处理，请使用正确的模板，已存在的商品会直接更新数据"
    }
  },
  data() {
    return {
      batchUploadGoodsDialogVisible: true,
      fileList: [],
      importSuccessData: {},
      importStatus: "init",
      isUplodSuccess: false,
      goodsImportAutoFetchTemplateUrl: `${goodsImportAutoFetchTemplateUrl}?t=${new Date().getTime()}`,
      goodsImportManualWorkTemplateUrl: `${goodsImportManualWorkTemplateUrl}?t=${new Date().getTime()}`,
      goodsImportDouyinTemplateUrl: `${goodsImportDouyinTemplateUrl}?t=${new Date().getTime()}`
    };
  },
  methods: {
    // 隐藏批量新增弹层
    hideImportFile() {
      this.$emit("callback", this.isUplodSuccess);
    },
    // 选择文件上传修改
    beforeUpload(file) {
      this.fileList = [file];
      return false;
    },
    onRemove(file,filelist){
      console.log(file,filelist)
    },
    // 手动上传
    handleUploadGoods() {
      const { fileList } = this;
      if (!fileList.length) {
        this.$message.error(`请选择文件`);
        return false;
      }
      const formData = new FormData();
      formData.append("file", fileList[0]);
      formData.append("upload_type", 1);
      this.importStatus = "uploading";
      axios({
        method: "post",
        url: `${baseURL}/api/v1/upload-file/`,
        data: formData
      })
        .then(response => {
          const { err, res } = response;
          if (!err) {
            if (res.success) {
              this.$message.success("批量导入完成");
              this.importSuccessData = res.data;
              this.importStatus = "done";
              this.isUplodSuccess = true;
            } else {
              // 认证信息不对等错误
              // this.$message.error(`${res.message}`);
              this.importStatus = "init";
              this.isUplodSuccess = false;
            }
          }
        })
        .catch(error => {
          this.$message.error(`${error}`);
          this.importStatus = "init";
          this.isUplodSuccess = false;
        });
    }
  }
};
</script>
<style lang="less" scoped>
.mb-10 {
  margin-bottom: 10px;
}
.upload-spin {
  text-align: center;
}
.lh-120 {
  line-height: 125%;

  p {
    margin-bottom: 20px !important;
  }
}
</style>
