/*
 * @Description: 供应商相关
 * @Autor: 胡椒
 * @Date: 2020-07-28 14:17:23
 * @LastEditors: 胡椒
 * @LastEditTime: 2020-07-31 13:43:59
 */

import axios from './axios';

// 供货商列表
export const supplierList = (params) => {
  return axios.get('/api/operators/v1/supplier/', { params });
};

// 添加供货商信息
export const addSupplier = (params) => {
  return axios.post('/api/operators/v1/supplier/', params);
};

// 更新供货商信息
export const editSupplier = (id, params) => {
  return axios.patch(`/api/operators/v1/supplier/${id}/`, params);
};
// 招商人员信息
export const supplieruserList = (params) => {
  return axios.get('/api/operators/v1/user-profile/', { params });
};

