<!--
 * @Author: 前胡
 * @LastEditors: 肖槿
 * @Date: 2020-03-04 13:41:32
 * @LastEditTime: 2021-10-26 14:41:27
 * @Description: 新增修改商品
 -->
<template>
  <div>
    <a-form :form="douyinForm" @submit="handleSubmit">
      <a-form-item label="所属公司" :label-col="{ span: 5 }" :wrapper-col="{ span: 19 }">
        <a-input disabled :value="postData.supplier" />
      </a-form-item>

      <a-form-item
        label="商品名称"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
        v-if="postData.data_source===2"
      >
        <a-input
          v-decorator="[
          'postData.item_name',
          { rules: [{ required: true, message: '请输入商品名称' },{
            max:120,
            message: '最多120个字',
          },{validator: checkBlank}]  },
        ]"
        />
      </a-form-item>
      <a-form-item label="商品ID" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input disabled :value="postData.item_id" />
      </a-form-item>
    <!--  <a-form-item label="商品商家编码" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input disabled :value="postData.supplier_item_id" />
      </a-form-item>-->
      <a-form-item label="商品链接" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <div style="position: relative"> <a-input style="position: absolute;width: 500px;" disabled :value="postData.item_url" ></a-input>
        <img @click="gotoNew(postData.item_url)" src="../../../assets/icon-share.png" style="display: inline-block;margin-top: 10px;width: 20px;height: 20px;position: absolute;margin-left: 510px"/>
        </div>
      </a-form-item>


      <a-form-item label="商品类目" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input
          disabled
          style="width: 300px"
          v-decorator="[
          'postData.merchant_category',
        ]"
        >
        </a-input>
      </a-form-item>

      <a-form-item label="商品品牌" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-select
          :getPopupContainer='triggerNode => triggerNode.parentElement'
          show-search
          :allowClear="true"
          :show-arrow="false"
          :filter-option="filterBrandOption"
          @change="handleBrandChange"
          style="width: 300px"
          v-decorator="[
          'postData.brand'
        ]"
          placeholder="请选择"
        >
          <a-select-option v-for="item in goodsBrandList" :key="item.id">{{item.name}}</a-select-option>
        </a-select>
      </a-form-item>
    <!--  <a-form-item label="划线价" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input  :value="postData.final_price" />
      </a-form-item>-->
      <a-form-item label="划线价"    :label-col="{ span: 5 }"  :wrapper-col="{ span: 12 }">
      <!--<a-input   v-decorator="[
          'postData.final_price',
        ]"  />-->
        <a-input-number
          type=number
          :min="0.00"
          :step="0.01"
          :max="10000000000000"
          style="width: 400px"
          @change="onChangeFinalPrice"
          :precision="2"
          v-decorator="[
            'postData.final_price',
            {
              rules: validatorRules.commission_final_price,
            }
          ]" />
    </a-form-item>

      <a-form-item label="销售价" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input-number
          type=number
          :min="0.00"
          :step="0.01"
          :max="10000000000000"
          style="width: 400px"
          @change="onChangeKuranPrice"
          :precision="2"
          v-decorator="[
            'postData.kuran_price',
            {
               rules: validatorRules.commission_kuran_price,
            }
          ]" />
      </a-form-item>
      <a-form-item label="佣金率" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input-number
          :min="0.00"
          :step="0.01"
          :max="100"
          style="width: 300px"
          @change="onChangeExternalCommission"
          :formatter="value => `${value}%`"
          :parser="value => value.replace('%', '')"
          placeholder="佣金率"
          :precision="2"
          v-decorator="[
            'postData.external_commission_rate',
            {
              initialValue: postData.external_commission_rate || undefined,
              rules: validatorRules.external_commission_rate,
            }
          ]" />
      </a-form-item>
      <!--      团长活动商品-->
      <a-form-item label="服务费率" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-input disabled :value="postData.campaign_product_service_rate?postData.campaign_product_service_rate+'%' : '' " />
      </a-form-item>
      <a-form-item
        label="商品轮播图"
        class="label-required"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 19 }"
      >
        <UploadSwiperImages
          :small_images="postData.slide_images"
          :pict_url="postData.pict_url"
          @callback="updateSiwperData"
        />
      </a-form-item>

      <a-form-item label="寄样模式" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-radio-group
          :disabled="isDisabledSend"
          :options="postData.is_kuran_selected==1 ? sendSampleOptions : sendSampleTwoOptions"
          @change="changeIsSupportSend"
          v-model="postData.is_support_send"
        />
      </a-form-item>
      <a-form-item
        label="样品回收"
        v-if="postData.is_support_send === 1"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-radio-group
          :options="isRecycleOptions"
          @change="changeIsRecycle"
          v-decorator="[
          'postData.is_recycle',
          { rules: [{ required: true, message: '请选择是否需要样品回收！' }] },
          ]"
        />
      </a-form-item>
      <a-form-item
        label="回寄地址"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
        v-if="isRecycleStatus===1 && postData.is_support_send === 1"
      >
        <div>
          <a-form-item label="收货人" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <a-input
              max="20"
              style="width: 300px"
              v-decorator="[
          'postData.receiver',
          { rules: [{ required: true, message: '请输入收货人' },{
            max:20,
            message: '最多20个字',
          } ]}
        ]"
            />
          </a-form-item>
          <a-form-item label="联系电话" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
            <a-input
              style="width: 300px"
              v-decorator="[
          'postData.receiver_mobile',{ rules: [{ required: true, message: '请输入手机号' },{ validator: checkPhoneNumber } ]}
        ]"
            />
          </a-form-item>
          <a-form-item label="收货地址" :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }">
            <a-textarea
              style="width: 300px"
              :autoSize="{ minRows: 4, maxRows: 6 }"
              v-decorator="[
          'postData.delivery_address',{ rules: [{ required: true, message: '请输入收货地址' },{
            max:100,
            message: '最多100个字',
          } ]}
        ]"
            />
          </a-form-item>
        </div>
      </a-form-item>
      <a-form-item label="产品利益点" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-textarea
          placeholder="请输入"
          :autoSize="{ minRows: 4, maxRows: 12 }"
          v-decorator="[
            'postData.benefit',
            {
              initialValue: postData.benefit,
            },
          ]"
          :maxLength="1000"
        />
      </a-form-item>
      <a-form-item label="直播卖点" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-textarea
          placeholder="请输入"
          :autoSize="{ minRows: 4, maxRows: 12 }"
          v-decorator="[
            'postData.sell_point',
          ]"
          :maxLength="1000"
        />
      </a-form-item>
      <a-form-item label="招商人员" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
        <a-select
          placeholder="招商人员"
          v-model="postData.merchant_user_profile_id"
          :default-active-first-option="false"
          show-search
          :allowClear="true"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="null"
          @search="handleSearchSupplier"
          :getPopupContainer='triggerNode => triggerNode.parentElement'
        >
          <a-select-option v-for="item in supplierPeopleList" :disabled="item.checked" :key="item.id">{{ item.username }}</a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item
        label="商品标签1"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-input
          v-model="postData.tag1"
          v-decorator="[
          'postData.tag1',
          { rules: [{
              validator: checkNotspecial,
          }] },
        ]"
          :maxLength="6"
        />
      </a-form-item>
      <a-form-item
        label="商品标签2"
        :label-col="{ span: 5 }"
        :wrapper-col="{ span: 12 }"
      >
        <a-input
          v-model="postData.tag2"
          v-decorator="[
          'postData.tag2',
          { rules: [{
              validator: checkNotspecial,
          }] },
        ]"
          :maxLength="6"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 12, offset: 5 }">
        <a-button class="mr-20" @click="handleHideDrawer">取消</a-button>
        <a-button
          type="primary"
          html-type="submit"
          :disabled="postDisabled"
          :loading="isPosttingKrGoods"
        >保存
        </a-button>
      </a-form-item>
    </a-form>
    <!--主副规格选择-->
    <SpecPickerModal
      v-if="isShowSpecPickerModal"
      :fillData="skuData"
      @callback="hideSpecPickerModal"
    />
  </div>
</template>
<script>
import UploadSwiperImages from "@/components/admin/goods/upload-swiper-images";
import { sourceOptions, sendSampleOptions, sendSampleTwoOptions } from "@/const/goods-cate.js";
import { postKrGoods, patchKrGoods, editGoodsTagsIn, getKrGoodsDetail } from "@/service/goods";
import { shopList, checkRelatedGoods } from "@/service/operate";
import SpecPickerModal from "@/components/admin/goods/spec-picker-modal";
import { userLevelOption } from "@/const/index";
import { supplieruserList } from '@/service/supplier'
import {
  checkPhoneNumber,
  checkUrl,
  checkBlank,
  checkNumber,
  checkNumberOverZero,
  checkIsNotZero,
  checkNotspecial
} from "@/utils/validator";
import { formatInputNumber2Decimal } from '@/utils'

// 是否设置排期
const schedulingTimeOptions = [
  { label: '是', value: 1 },
  { label: '否', value: 0 }
];
// 是否上架选择项
const krStatusOptions = [
  { label: "上架", value: 1 },
  { label: "下架", value: 2 }
];
// 是否支持寄样选择项
const isSupportSampleOptions = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
];
// 是否支持新人寄样
const isSupportNewOptions = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
];
// 是否回收选择项
const isRecycleOptions = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
];
// 数据来源
const dataSourceOptions = [
  { label: "自动获取", value: 1 },
  { label: "后台维护", value: 2 }
];
// 是否sku
const isHasSpecOptions = [
  { label: "是", value: 1 },
  { label: "否", value: 0 }
];

export default {
  name: "DouyinGoodsData",
  props: {
    id: {
      default: null
    },
    drawerType: {
      default: "CREATE",
      type: String
    },
    goodsData: {
      default: function () {
        return {};
      },
      type: Object
    },
    goodsCate: {
      default: function () {
        return [];
      },
      type: Array
    },
    goodsBrandList: {
      default: function () {
        return [];
      },
      type: Array
    },
    supplierList: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    UploadSwiperImages,
    SpecPickerModal
  },
  data() {
    return {
      goodsdetailData: {},
      checkNotspecial,
      supplierPeopleList:[],
      validatorRules: {
        // 是否设置排期
        has_schedule: [
          {
            required: true,
            message: '请选择是否设置排期',
          },
        ],

        // 排期时间
        schedule_time: [
          {
            required: true,
            message: '请选择排期时间',
          }
        ],

        // 佣金是否需要申请 true: 是、false: 否
        is_need_apply_commission: [
          {
            required: true,
            message: '请选择是否需要申请佣金',
          },
        ],
// shoujia
        commission_kuran_price: [
          {
            required: true,
            message: '请填写销售价',
          },
          {
            validator: checkNumberOverZero,
          }
        ],
        commission_final_price: [
          {
            required: true,
            message: '请填写划线价',
          },
          {
            validator: checkNumberOverZero,
          }
        ],
        // 佣金比例
        commission_divide_100_rate: [
          {
            required: true,
            message: '请填写定向佣金率',
          },
          {
            validator: checkNumberOverZero,
          }
        ],
        // 对外佣金比例
        external_commission_rate: [
          {
            required: true,
            message: '请填写佣金率',
          },
          {
            validator: checkNumberOverZero,
          }
        ],

        // 优惠券码
        commission_code: [
          {
            pattern: /^[0-9A-Za-z]{0,20}$/,
            message: '最多20个字，限数字和字母',
          },
        ],

        // 折一等级
        kuran_level_require: [
          {
            validator: (rule, value, callback) => {
              if (this.postData.is_support_send === 1 && this.kuranLevelRequireStatus && !value) {
                return callback('请选择折一等级');
              }
              callback();
            },
          },
        ],

        // 粉丝量
        fans_require: [
          {
            validator: (rule, value, callback) => {
              if (this.postData.is_support_send === 1 && this.fansRequireStatus && !value) {
                return callback('请输入粉丝量');
              }
              callback();
            },
          },
        ],

        // 作品数
        works_require: [
          {
            validator: (rule, value, callback) => {
              if (this.postData.is_support_send === 1 && this.worksRequireStatus && !value) {
                return callback('请输入作品数');
              }
              callback();
            },
          },
        ],

        // 点赞数
        like_require: [
          {
            validator: (rule, value, callback) => {
              if (this.postData.is_support_send === 1 && this.likeRequireStatus && !value) {
                return callback('请输入点赞数');
              }
              callback();
            },
          },
        ],

        // 场观数
        pv_require: [
          {
            validator: (rule, value, callback) => {
              if (this.postData.is_support_send === 1 && this.pvRequireStatus && !value) {
                return callback('请输入场观数');
              }
              callback();
            },
          },
        ],

        // 场均GMV
        gmv_require: [
          {
            validator: (rule, value, callback) => {
              if (this.postData.is_support_send === 1 && this.gmvRequireStatus && !value) {
                return callback('请输入场均GMV');
              }
              callback();
            },
          },
        ],

        // 供应商
        supplier_require: [
          {
            required: true,
            message: '请选择供货商',
          },
        ]
      },
      kuran_level_list:['Lv1','Lv2','Lv3','Lv4','Lv5'],
      schedulingTimeOptions,
      checkPhoneNumber,
      checkUrl,
      checkBlank,
      checkNumber,
      krStatusOptions,
      isSupportSampleOptions,
      isSupportNewOptions,
      sourceOptions,
      isRecycleOptions,
      dataSourceOptions,
      userLevelOption,
      checkNumberOverZero,
      isHasSpecOptions,
      formatInputNumber2Decimal,
      checkIsNotZero,
      isLoadingExp: false,
      isDisabledSend: false,
      postDisabled: false,
      douyinForm: this.$form.createForm(this),
      isPosttingKrGoods: false,
      isRecycleStatus: 1,
      formatData: {},
      couponStatus: false,
      paiCutStatus: false,
      paiOverStatus: false,
      giftStatus: false,
      isShowSpecPickerModal: false,
      kuranLevelRequireStatus: false, // 折一等级复选框
      fansRequireStatus: false, // 粉丝量复选框
      worksRequireStatus: false, // 作品数复选框
      likeRequireStatus: false, // 点赞数复选框
      pvRequireStatus: false, // 场观数复选框
      gmvRequireStatus: false, // 场均GMV复选框
      // 折一专享CheckBox状态 end
      isCommissionUrlOk: true, // 申请链接是否包含campaignId，shopkeeperId，userNumberId
      postData: {
        item_id: null,
        item_name: "",
        custom_cat: 8,
        source: 1,
        is_kuran_selected: 0, // 是否折一优选
        has_schedule: 1, // 排期时间
        schedule_start_date: '', // 排期开始时间
        schedule_end_date: '', // 排期结束时间
        kuran_price: undefined,
        sale_price: undefined,
        final_price: undefined,
        commission_rate: undefined, // 提交的佣金比例
        commission_divide_100_rate: undefined, // 用于显示的佣金比例
        slide_images: [],
        pict_url: null,
        shop_title: undefined,
        is_on_sale: 1,
        reduction_amount: undefined,
        buy_send: {
          buy_count: undefined,
          send_count: undefined
        },
        gift_info: undefined,
        buy_remark: undefined,
        is_support_send: 1,
        is_support_new: 0,
        new_inventory: undefined,
        freeze_inventory: undefined,
        is_recycle: 1,
        receiver: undefined,
        delivery_address: undefined,
        receiver_mobile: undefined,
        benefit: '', // 产品利益点
        sell_point: undefined,
        item_url: undefined,
        supplier: "",
        supplier_item_id: "",
        goods_channel: 2,
        coupon_range_time: undefined,
        brand: undefined,
        sku_list: [],
        merchant_user_profile_id: "",
        merchant_user_profile_username: "",
        tag1:'',
        tag2:''
      },
      is_has_spec: 1,
      skuData: {
        main_spec: undefined,
        sub_specs: [],
        spec_value: {},
        id_map: {}
      },
      shopSearchList: [],
      sendSampleOptions,
      sendSampleTwoOptions,
      associated_goods_totol: [],  // 控制点击关联商品出现输入框
      associated_goods_tips: [],  // 关联商品校验提示文案
    };
  },
  created() {
    this.handleSearchSupplier('');
    this.goodsdetailData = this.goodsData;
    this.getDetail();
  },
  computed: {
    // 预计佣金
    commissionValue: function () {
      if (
        this.postData.kuran_price &&
        this.postData.commission_divide_100_rate
      ) {
        const v = (
          (this.postData.kuran_price *
            this.postData.commission_divide_100_rate) /
          100
        ).toFixed(2);
        return v;
      }
      return 0;
    },
    newInventoryStr: function () {
      return `当前可用库存：${this.goodsdetailData.new_inventory}，当前冻结库存：${this.goodsdetailData.freeze_inventory}`;
    }
  },
  mounted() {
    if (this.drawerType === "CREATE") {
      this.mounted();
    }
  },
  methods: {
    mount(){
      if (this.drawerType !== "CREATE") {
        if (typeof this.postData.is_kuran_selected != undefined) {
          this.postData.is_kuran_selected = this.postData.is_kuran_selected ? 1 : 0;
        }
        if (typeof this.postData.has_schedule != undefined) {
          this.postData.has_schedule = this.postData.has_schedule ? 1 : 0;
        }
        if (typeof this.postData.is_need_apply_commission !== undefined) {
          this.postData.is_need_apply_commission = this.postData.is_need_apply_commission ? 1 : 0;
        }
        this.postData.commission_divide_100_rate = this.postData.commission_rate
          ? this.postData.commission_rate / 100
          : undefined;
        this.postData.external_commission_rate = this.postData.external_commission_rate
          ? this.postData.external_commission_rate / 100
          : undefined;
        this.postData.campaign_product_service_rate = this.postData.campaign_product_service_rate
          ? this.postData.campaign_product_service_rate
          : undefined;

        if (this.postData.reduction_amount) {
          this.paiCutStatus = true;
        }
        // 初始化 折一等级、粉丝量、作品数、点赞数、场观数、场均GMV checkbox
        if (this.postData.kuran_level_require) {
          this.kuranLevelRequireStatus = true;
        }
        if (this.postData.fans_require) {
          this.fansRequireStatus = true;
        }
        if (this.postData.works_require) {
          this.worksRequireStatus = true;
        }
        if (this.postData.like_require) {
          this.likeRequireStatus = true;
        }
        if (this.postData.pv_require) {
          this.pvRequireStatus = true;
        }
        if (this.postData.gmv_require) {
          this.gmvRequireStatus = true;
        }
        if (
          this.postData.buy_send &&
          this.postData.buy_send &&
          this.postData.buy_send.buy_count &&
          this.postData.buy_send.send_count
        ) {
          this.paiOverStatus = true;
        }
        if (this.postData.gift_info) {
          this.giftStatus = true;
        }
        if (this.postData.sku_list&&this.postData.sku_list.length) {
          this.is_has_spec = 1;
          this.skuData = this.postData.sku_spec_data;
        } else {
          this.is_has_spec = 0;
        }

        //  关联商品
        this.associated_goods_totol = this.postData.related_goods
        if(this.postData.related_goods){
          this.associated_goods_tips = this.postData.related_goods.map(() => {
            return ""
          })
        }
      }
      const postData = this.postData;
      const prePostData = {};
      for (const i in postData) {
        prePostData["postData." + i] = postData[i];
      }
      this.$nextTick(() => {
        this.douyinForm.setFieldsValue({
          couponStatus: this.couponStatus,
          paiCutStatus: this.paiCutStatus,
          paiOverStatus: this.paiOverStatus,
          giftStatus: this.giftStatus,
          ...prePostData,
          is_has_spec: this.is_has_spec
        });
      });
    },
    init(){
        const goodsData = JSON.parse(JSON.stringify(this.goodsdetailData));
        goodsData.custom_cat = goodsData.item_id__custom_cat;
        goodsData.final_price = goodsData.item_id__final_price;
        goodsData.slide_images = goodsData.item_id__slide_images || [];
        goodsData.pict_url = goodsData.item_id__pict_url;
        goodsData.item_url = goodsData.item_id__item_url||'';
        this.postData = goodsData;
        this.postData.merchant_user_profile_id= this.postData.merchant_user_profile_id===0 ? '' : this.postData.merchant_user_profile_id;
        var one=goodsData.item_id__first_cat_name?(goodsData.item_id__first_cat_name+'/'):'';
        var two=goodsData.item_id__second_cat_name?(goodsData.item_id__second_cat_name+'/'):'';
        var three=goodsData.item_id__third_cat_name?goodsData.item_id__third_cat_name:'';
        this.postData.tag1=goodsData.item_tag_list?goodsData.item_tag_list[0]:'';
        this.postData.tag2=goodsData.item_tag_list?goodsData.item_tag_list[1]:'';
        var allstr=one+two+three;
        var arr=allstr.split('')||[];
        if(arr.length>0 && arr[arr.length-1]==='/'){
          arr.splice(arr.length-1,1);
        }
        this.postData.merchant_category=arr.join('');
        this.isRecycleStatus = this.postData.is_recycle;
    },
     async getDetail() {
       if (this.drawerType !== "CREATE") {
         const {err, res} = await getKrGoodsDetail({id: this.goodsData.item_id});
         if (!err && res.success) {
           this.goodsdetailData = res.data;
         }
         this.init();
         this.mount();
       }
    },
    handleSearchSupplier(str) {
      supplieruserList({
        search: str
      }).then((res) => {
        if (res.res.success === true) {
          this.supplierPeopleList = res.res.data.results;
          var ishand=false;
          this.supplierPeopleList=this.supplierPeopleList.map(item=>{
           if(this.postData.merchant_user_profile_id===item.id) {
             ishand=true;
           }
           item.checked=false;
           return item;
          });
          if(ishand===false&&this.postData.merchant_user_profile_id!==0){
            this.supplierPeopleList.push({
              checked: true,
              id: this.postData.merchant_user_profile_id,
              username: this.postData.merchant_user_profile_username,
            })
          }
        }
      })
    },
    gotoNew(item_url){
      if(item_url&&item_url!==""){
        window.open(item_url);
      }

    },
    // 添加关联商品
    addAssociated() {
      this.associated_goods_totol.push(null);
      this.associated_goods_tips.push(null);
    },

    // 删除关联商品
    deleteAssociated(index) {
      this.associated_goods_totol.splice(index, 1);
    },

    // 商品ID赋值
    getGoodsIdValue(rule, value, cb) {
      this.postData.item_id = value;
      cb()
    },

    //  关联商品失去焦点校验
    async associatedBlur(index) {

      // 判断是否有重复
      let isRepeat = false;
      for (const i in this.associated_goods_totol) {
        if (i != index) {
          if (this.associated_goods_totol[index] == this.associated_goods_totol[i]) {
            isRepeat = true;
            break;
          }
        }
      }

      if (isRepeat) {
        this.associated_goods_tips.splice(index, 1, '该商品已存在');
        return;
      } else {
        this.associated_goods_tips.splice(index, 1, '');
      }

      // 商品ID不为空再校验
      if (this.associated_goods_totol[index]) {
        const { err, res } = await checkRelatedGoods({
          item_id: this.postData.item_id,
          related_item_id: this.associated_goods_totol[index]
        });
        if (!err) {
          if (res.success) {
            this.associated_goods_tips.splice(index, 1, "");
          } else {
            this.associated_goods_tips.splice(index, 1, res.message);
          }
        }
      }
    },

    // 显示规格选择
    showSpecModal() {
      this.isShowSpecPickerModal = true;
    },
    // 隐藏规格选择
    hideSpecPickerModal(data = null) {
      this.isShowSpecPickerModal = false;
      if (data) {
        const callbackData = JSON.parse(JSON.stringify(data));
        this.skuData = callbackData;
        this.$set(this.postData, "sku_list", callbackData.sku_list);
      }
    },
    //提交信息
    async  commitgood() {
        if (this.postData.tag1 && this.postData.tag1.length > 0 && this.postData.tag1 === this.postData.tag2) {
          this.$message.error('商品标签1与商品标签2相同，请修改后保存')
        }else{
          var tag1=this.postData.item_tag_list?this.postData.item_tag_list[0]:'';
          var tag2=this.postData.item_tag_list?this.postData.item_tag_list[1]:'';
          if(this.postData.tag1 === tag1 && this.postData.tag2 === tag2){
            return ;
          }
          const token = this.$store.getters.getToken;
          const Authorization = token ? token : undefined;
          var data = {
            item_id: this.postData.item_id,
            Authorization: Authorization,
            item_tag_list: [this.postData.tag1 || '', this.postData.tag2 || '']
          }
          const {err, res} = await editGoodsTagsIn(data);
          if (!err) {
            if (res.success) {
              // this.$message.success("更新成功！");
            }
          }
        }
    },
    handleSubmit(e) {
      e.preventDefault();
      const that = this;
      this.douyinForm.validateFields((err, values) => {
        if (!err) {
          this.commitgood();
          const data = JSON.parse(JSON.stringify({ ...this.postData, ...values.postData }));
          data.data_source = 2;
          data.is_support_send = this.postData.is_support_send;
          data.item_tag_list=[this.postData.tag1||'',this.postData.tag2||''];
          if (!this.paiCutStatus) {
            data.reduction_amount = null;
          }
          if (!this.paiOverStatus) {
            data.buy_send = null;
          }
          if (!this.giftStatus) {
            data.gift_info = "";
          }
          if (!this.paiCutStatus && !this.paiOverStatus && !this.giftStatus) {
            data.buy_remark = "";
          }
          data.is_recycle = this.isRecycleStatus;
          data.merchant_user_profile_id=this.postData.merchant_user_profile_id;
          data.slide_images = this.postData.slide_images || [];
          data.pict_url = this.postData.pict_url;
       /*   data.commission_rate = parseInt(
            data.commission_divide_100_rate * 100
          );*/
          //非必填
          if(data.external_commission_rate) {
            data.external_commission_rate = parseInt(data.external_commission_rate * 100)
          }
          //非必填
          if(data.campaign_product_service_rate) {
            data.campaign_product_service_rate = parseInt(data.campaign_product_service_rate * 100)
          }
          data.goods_channel = 2;

          if (data.slide_images.length === 0) {
            this.$message.error("请添加轮播图！");
            return false;
          }

          // 不支持寄样时，新人寄样，新人库存不设置
          if (data.is_support_send === 0) {
            data.is_support_new = 0;
            data.new_inventory = undefined;
            data.is_recycle = 0;

            // 不支持寄样，不设置申报条件
            data.kuran_level_require = null;
            data.fans_require = '';
            data.works_require = '';
            data.like_require = '';
            data.pv_require = '';
            data.gmv_require = '';
          } else if (data.is_support_send === 1) {
            // 若未选中，不设置申报条件
            if (!this.kuranLevelRequireStatus) {
              data.kuran_level_require = null;
            }
            if (!this.fansRequireStatus) {
              data.fans_require = '';
            }
            if (!this.worksRequireStatus) {
              data.works_require = '';
            }
            if (!this.likeRequireStatus) {
              data.like_require = '';
            }
            if (!this.pvRequireStatus) {
              data.pv_require = '';
            }
            if (!this.gmvRequireStatus) {
              data.gmv_require = '';
            }
          }

          if (data.is_support_new === 0) {
            data.new_inventory = undefined;
          }

          // sku
          data.sku_list = this.postData.sku_list;
          // 品牌
          data.brand = this.postData.brand;

          if (!data.supplier) {
            data.supplier = '';
          }

          // 判断有规格时，是否未设置规格
          if (this.is_has_spec === 1 && (!data.sku_list || (data.sku_list && data.sku_list.length === 0))) {
            that.$message.error('请配置规格');
            return false
          } else {
            // 配置了规格，判断是否有折一价未填写
            const rs = data.sku_list.every(i => i.kuran_price > 0 && i.final_price > 0)
            if (!rs) {
              that.$message.error('请填写完整SKU明细中的价格');
              return false
            }
          }

          if (this.is_has_spec === 1) {
            // 有规格
            data.kuran_price = undefined;
          } else {
            // 没有规格
            data.sku_list = [];
          }

          //  关联商品重复id判断
          const isRepeat = this.associated_goods_tips.some((item) => {
            return item === '该商品已存在'
          })
          if (isRepeat) {
            that.$message.error('该商品已存在');
            return
          }

          //  关联商品
          if (this.postData.is_support_send === 2) {
            const tempList = this.associated_goods_totol.filter(item => {
              return item && item;
            })
            data.related_item_ids = tempList.toString();
          }
          if (this.drawerType === "CREATE") {
            // 创建
            this.postKrGoodsData(data);
          }
          if (this.drawerType === "EDIT") {
            // 更新
            data.item_id = this.goodsData.item_id;
            this.patchKrGoodsData(data);
          }
          if (this.drawerType === "PUTAWAY") {
            // 上架
            data.item_id = this.goodsData.item_id;
            this.postKrGoodsData(data);
          }
        }
      });
    },
    // 改变支持寄样
    changeIsSupportSend(e) {
      const v = e.target.value;
      this.postData.is_support_send = v;
    },
    // 改变新人寄样
    changeIsSupportNew(e) {
      this.postData.is_support_new = e.target.value;
    },
    // 商品类目
    changeCustomCat(value) {
      this.postData.custom_cat = value;
    },
    // 拍下件数要小于实发件数
    comparePaiAndSend(rule, value, callback) {
      const form = this.douyinForm;
      if (value && value <= form.getFieldValue("postData.buy_send.buy_count")) {
        callback("拍下件数要小于实发件数!");
      } else {
        callback();
      }
    },
    // 修改折一价格
    onChangeKuranPrice(value) {
      this.postData.kuran_price = Number(value).toFixed(2);
    },
    // 修改折一价格
    onChangeFinalPrice(value) {
      this.postData.final_price = Number(value).toFixed(2);
    },
    // 修改佣金比例
    onChangeCommission(value) {
      this.postData.commission_divide_100_rate = value;
    },
    // 修改佣金比例
    onChangeExternalCommission(value) {
      this.postData.external_commission_rate = value;
    },
    // 修改服务费率
    onChangeCampaignProductServiceRate(value) {
      this.postData.campaign_product_service_rate = value;
    },
    // 新增折一商品
    async postKrGoodsData(data) {
      this.isPosttingKrGoods = true;
      const { err, res } = await postKrGoods(data);
      this.isPosttingKrGoods = false;
      if (!err) {
        if (res.success) {
          this.$message.success("成功！");
          this.handleHideDrawer(1);
        }
      }
    },
    // 更新折一商品
    async patchKrGoodsData(data) {
      this.isPosttingKrGoods = true;
      const { err, res } = await patchKrGoods(data);
      this.isPosttingKrGoods = false;
      if (!err) {
        if (res.success) {
          this.$message.success("成功！");
          this.handleHideDrawer(0);
        }
      }
    },
    // 隐藏新增编辑商品drawer
    handleHideDrawer(isNeedRefresh = -1) {
      this.$emit("hideGoodsDrawer", isNeedRefresh);
    },
    // 切换样品回收
    changeIsRecycle(e) {
      this.isRecycleStatus = e.target.value;
    },
    // 更新siwper数据
    updateSiwperData(e) {
      this.postData.slide_images = e.small_images;
      this.postData.pict_url = e.pict_url;
    },
    // 切换品牌
    handleBrandChange(e) {
      this.$set(this.postData, "brand", e);
    },
    // 品牌过滤
    filterBrandOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    // 切换是否有规格
    changeHasSpec(e) {
      this.is_has_spec = e.target.value
      // this.$set(this.postData, "is_has_spec", e.target.value);
      // if (e.target.value === 0) {
      //   this.$set(this.postData, "sku_list", []);
      //   this.skuData = {
      //     main_spec: undefined,
      //     sub_specs: [],
      //     spec_value: {},
      //     id_map: {}
      //   };
      // } else {
      //   this.$set(this.postData, "kuran_price", undefined);
      //   this.$set(this.postData, "final_price", undefined);
      // }
    },
    // 获取抖音商品数据
    async getShopList(e) {
      const douyin = e.target.value;
      const { err, res } = await shopList({ shop_title: douyin });
      if (!err && res.success) {
        const shops = res.data.results;
        this.shopSearchList = shops;
      }
    },
    async onChangeShopTitle(e) {
      const douyin = e;
      const { err, res } = await shopList({ shop_title: douyin });
      if (!err && res.success) {
        const shops = res.data.results;
        this.shopSearchList = shops.map(i => ({
          text: i.shop_title,
          value: i.shop_title
        }));
      }
    },

    // 日期选择
    onScheduleChange(date, [startTime, endTime]) {
      if (startTime) {
        this.douyinForm.setFieldsValue({
          'postData.schedule_start_date': startTime,
        });
      }
      if (endTime) {
        this.douyinForm.setFieldsValue({
          'postData.schedule_end_date': endTime,
        });
      }
    },
  }
};
</script>
<style lang="less">
.mr-20 {
  margin-right: 20px;
}

.goodsLi {
  display: flex;
  align-items: center;
  height: 39px;
}

.kr-table {
  th,
  td {
    padding: 5px;
    font-weight: normal;
  }
}

.paiover-dd {
  .ant-row {
    margin-bottom: 0;
  }
}

.label-required .ant-form-item-label label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.condition .ant-form-item {
  position: relative;
  margin-bottom: 0;

  .ant-form-explain {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    height: 22px;
    left: 100%;
    right: -100%;
    margin-left: 10px;
  }
}
</style>
<style lang="less" scoped>

</style>
