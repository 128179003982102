<!--
 * @Author: 前胡
 * @LastEditors: 矢车
 * @Date: 2020-03-03 17:16:48
 * @LastEditTime: 2020-09-28 16:59:49
 * @Description: 商品管理 -- 商品列表
 -->
<template>
  <div id="specialManage">
    <div class="tab-content">
      <GoodsSearchBar v-auth="'goods_manage_goods_list'" @callback="searchBarCallback" :searchParams="searchParams" :goodsCate="goodsCatesList" :loading="listLoading" @handleBatchPutawayGoods="handleBatchPutawayGoods" @handleBatchDownGoods="handleBatchDownGoods"/>
      <div class="option-btns">
        <!--        <a-button v-auth="'goods_manage_add_goods'" type="primary" class="option-btn" html-type="submit" @click="handleShowCreateGoodsDialog()">新增商品</a-button>
       <a-button v-auth="'goods_manage_batch_add_goods'" type="primary" class="option-btn" html-type="submit" @click="handleShowBatchUploadGoodsDialog()">批量导入</a-button>
       <a-button v-auth="'goods_manage_batch_upshelf_goods'" type="primary" class="option-btn" html-type="submit" @click="handleBatchPutawayGoods()">批量上架</a-button>
        <a-button v-auth="'goods_manage_batch_downshelf_goods'" type="primary" class="option-btn" html-type="submit" @click="handleBatchDownGoods()">批量下架</a-button>
      <a-button v-auth="'goods_manage_batch_import_result_download'" type="primary" class="option-btn" @click="handleDownloadImportResultsClick()">批量导入结果下载</a-button>-->

        <!--        <a-button v-auth="'goods_manage_batch_timing_upshelf_goods'" type="primary" class="option-btn" @click="handleBatchTime('up')">定时上架</a-button>-->

        <!--        <a-button v-auth="'goods_manage_batch_timing_downshelf_goods'" type="primary" class="option-btn" @click="handleBatchTime('down')">定时下架</a-button>-->
<!--        <a-button v-auth="'goods_manage_export'" type="primary" class="option-btn" @click="handleExport()" :loading="listLoading">商品导出</a-button>-->

      </div>
      <a-table class="tablecl" :scroll="{ x: 1000,y: tableScrollY }" :current="pagination.current" v-auth="'goods_manage_goods_list'" :rowKey="record => record.item_id" :columns="goodsColumns" :dataSource="list" :pagination="pagination" :loading="listLoading" :locale="{ emptyText: '暂无商品' }" @change="handleTableChange" :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}">
        <template slot="goods_img" slot-scope="record">
<!--            <el-image  @click="showImgClick(record)" v-if="record.item_id__pict_url||record.item_id__slide_images[0]" lazy class="el-img" :src="record.item_id__pict_url||record.item_id__slide_images[0]||''" mode="aspectFit" fit="fill"/>-->
<!--       <viewer>     <img style="width: 50px;" :src="record.item_id__pict_url||record.item_id__slide_images[0]" /> </viewer> -->
        <my-image v-if="record.item_id__pict_url||record.item_id__slide_images[0]" :imgUrl="record.item_id__pict_url||record.item_id__slide_images[0]"></my-image>
        </template>
        <template slot="goods_id" slot-scope="record">{{record.item_id}}</template>
        <template slot="is_support_send" slot-scope="record">
          <a-tag :color="sampleModeMethods(record.is_support_send).color">{{sampleModeMethods(record.is_support_send).label}}</a-tag>
        </template>
        <template slot="discount_price" slot-scope="record">
          ￥{{record.kuran_price}}
        </template>
        <template slot="external_commission_rate" slot-scope="record">
          {{record.external_commission_rate / 100}}%
        </template>
        <template slot="commission_service_rate" slot-scope="record">
          {{record.campaign_product_service_rate ? record.campaign_product_service_rate+'%' : ''}}
        </template>
        <template slot="kr_status" slot-scope="record">
          <a-tag v-if="record.is_on_sale===1" color="green">上架</a-tag>
          <a-tag v-if="record.is_on_sale===2" color="red">下架</a-tag>
        </template>
        <template slot="is_recycle" slot-scope="record">
          <a-tag v-if="record.is_recycle===0" color="green">否</a-tag>
          <a-tag v-if="record.is_recycle===1" color="red">是</a-tag>
        </template>
        <template slot="operation" slot-scope="record">
          <a-button type="primary" size="small" class="mr-10" @click="handleEditGoods(record)" v-auth="'goods_manage_modify_goods'">编辑</a-button>
          <a-button size="small" v-if="record.is_on_sale===2" class="mr-10" @click="handleOnePutDownGoods(record,1)" v-auth="'goods_manage_timing_upshelf_downshelf_goods'">上架</a-button>
          <a-button size="small" v-if="record.is_on_sale===1" class="mr-10" @click="handleOnePutDownGoods(record,2)" v-auth="'goods_manage_timing_upshelf_downshelf_goods'">下架</a-button>
<!--          <a-button size="small" @click="handleOpenActive(record)">-->
<!--            活动提报-->
<!--          </a-button>-->
        </template>
        <template slot="goodtag" slot-scope="record">
         <!-- <div  style="width: 320px">
          <a-input
            :maxLength="6"
            @blur="commitgood(record)"
            v-model="record.tag1"
            style="display: inline-block;width: 120px;float: left" placeholder="标签1" />
          <a-input :maxLength="6"  @blur="commitgood(record)"    v-model="record.tag2" style="display: inline-block;width: 120px;float: left;margin-left: 10px" placeholder="标签2" />
          </div>-->
          <div style="height: 70px !important;width: 250px;overflow:hidden;margin-top: -15px;">
            <div  style="width: 250px;height: 50px !important;padding-top: 25px !important;">
              <a-form-model layout="vertical" style="width: 250px;height: 28px;margin: 0px;padding: 0px;" :model="record" :rules="rules">
                <a-form-model-item
                  prop="tag1"
                  style="width: 120px;display: inline-block;float: left;height: 28px;"
                >
                  <a-input style="height: 28px !important;font-size: 13px;width: 120px;" placeholder="标签1"  @blur="commitgood(record)" v-model.trim="record.tag1" :maxLength="6"></a-input>
                </a-form-model-item>
                <a-form-model-item
                  prop="tag2"
                  style="width: 120px;display: inline-block;float: left;margin-left: 10px;height: 28px;"
                >
                  <a-input style="height: 28px !important;font-size: 13px;width: 120px;"   placeholder="标签2" @blur="commitgood(record)" v-model.trim="record.tag2" :maxLength="6"></a-input>
                </a-form-model-item>
              </a-form-model>
            </div>
          </div>
        </template>
      </a-table>
    </div>
    <!-- 新增商品检查弹层 -->
    <a-modal class="goods-dialog" title="新增商品" v-model="createGoodsDialogVisible" centered>
      <a-form :form="goodsForm">
        <a-form-item label="商品ID" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-input-number style="width:300px" placeholder="请输入商品的淘宝ID" @change="handleChangeTempGoodsId" v-decorator="[
          'tempGoodsId',
          { rules: [{ required: true, message: '请输入淘宝ID' }] },
        ]"></a-input-number>
          <a-alert v-if="!isVaildGoodsId" type="error" :message="goodsIdInvaildMsg" banner />
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button
          key="submit-step1"
          type="primary"
          :loading="createGoodsLoading"
          @click="handleCheckGoodsStatus"
        >下一步
        </a-button>
      </template>
    </a-modal>
    <!-- 批量新增弹层 -->
    <ImportFile v-if="batchUploadGoodsDialogVisible" @callback="importFileCallback" />
    <!--新建商品-->
    <a-drawer
      :title="goodsDrawerTitle"
      placement="right"
      width="850"
      @close="handleCreateGoodsDrawerClose"
      :visible="createGoodsDrawerVisiable"
    >
      <GoodsData
        v-if="createGoodsDrawerVisiable"
        :id="tempGoodsId"
        :drawerType="drawerType"
        :goodsData="currentGoodsData"
        :taobaoUnionData="taobaoUnionData"
        :goodsCate="goodsCatesList"
        :goodsBrandList="goodsBrandList"
        :supplierList="supplierList"
        @hideGoodsDrawer="handleCreateGoodsDrawerClose"
      />
    </a-drawer>
    <!--定时上下架-->
    <TimingOnDownModal :data="currentGoodsData" v-if="timingOnDownDrawerVisiable" @callback="handleTimingOnDownDrawerClose" />
    <!--批量定时上下架-->
    <BatchTimingOnDownModal :batchItems="batchItems" v-if="isBatchTimingOnDown" :timeType="currentBatchTimingOnDownType" @callback="handleHideBatchTime" @batchCallback="handleBatchResult" @refresh="refreshData" />
    <!--批量定时上下架结果-->
    <BatchTimingOnDownResultModal v-if="isBatchTimingOnDownResultShow" :batchResult="batchResult" @callback="handleBatchTimingOnDownResultCallback" @hidemodal="hideBatchTimingOnDownResultModal" />
    <ImportResultDownloadModal ref="importResultDownloadModal" />
    <!--新增商品选取商品渠道-->
    <a-modal class="goods-dialog" title="新增商品" v-model="chooseGoodsChannelVisible" centered>
      <a-form :form="goodsTypeForm">
        <a-form-item label="商品渠道" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }">
          <a-radio-group v-model="currentGoodsChannel">
            <a-radio :value="1">淘宝联盟</a-radio>
            <a-radio :value="2">抖音小店</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="submit-step2" type="primary" @click="showGoodsNextDialog">下一步</a-button>
      </template>
    </a-modal>
    <a-modal title="活动提报" v-model="visibleTB" width="400px" @ok="activeOk">
      <div class="dialog-active-tb">
        <a-select placeholder="输入活动ID或者名称并选择" show-search @search="handleSearch" :filter-option="false" v-model="active_id">
          <a-select-option v-for="(item) in active_tb_list" :key="item.id">{{item.campaign_name}}</a-select-option>
        </a-select>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {
  getKrGoodsList,
  getCheckKrGoods,
  patchGoodsStatus,
  getGoodsCategorys,
  exportKrGoodsList,
  searchActiveList,
  relationActive,
  editGoodsTagsIn
} from "@/service/goods";
import {
  brandList
} from "@/service/operate";
import { supplierList } from "@/service/supplier";
import GoodsData from "@/components/admin/goods/goods-data-v2";
import TimingOnDownModal from "@/components/admin/goods/modal-timing-on-down";
import BatchTimingOnDownModal from "@/components/admin/goods/modal-batch-timing-on-down";
import BatchTimingOnDownResultModal from "@/components/admin/goods/modal-batch-timing-on-down-result";
import GoodsSearchBar from "@/components/admin/goods/goods-search-bar";
import ImportFile from "@/components/admin/goods/import-file";
import ImportResultDownloadModal from "@/components/admin/goods/import-result-download-modal";
import store from "@/store/index";
import { checkPer } from "@/utils/index";
import { sendSampleOptions } from '@/const/goods-cate.js'
import { message } from 'ant-design-vue';
import {
  checkNotspecial
} from "@/utils/validator";


// 商品列表
const goodsColumns = [
  {
    title: "商品图片",
    fixed: 'left',
    width: 100,
    scopedSlots: { customRender: "goods_img" },
  },
  {
    title: "商品编码",
    fixed: 'left',
    width: 140,
    scopedSlots: { customRender: "goods_id" },
  },
  {
    title: "商品名称",
    dataIndex: "item_name",
    width: 300,
    align: "left",
  },
  {
    title: "所属公司",
    dataIndex: "supplier",
    width: 200,
    align: "left",
  },
  {
    title: "品牌",
    dataIndex: "brand__name",
    width: 120,
    align: "left",
  },
  {
    title: "销售价",
    scopedSlots: { customRender: "discount_price" },
    width: 150,
    align: "left",
  },
  {
    title: "佣金率",
    scopedSlots: { customRender: "external_commission_rate" },
    width: 100,
    align: "left",
  },
  {
  title: "服务费率",
    scopedSlots: { customRender: "commission_service_rate" },
    width: 100,
    align: "left",
  },
  {
    title: "上架状态",
    scopedSlots: { customRender: "kr_status" },
    width: 110,
  },
  {
    title: "寄样模式",
    align: "center",
    scopedSlots: { customRender: "is_support_send" },
    width: 100,
  },
  {
    title: "样品回收",
    scopedSlots: { customRender: "is_recycle" },
    width: 100,
    align: "left",
  },
  {
    title: "标签",
    scopedSlots: { customRender: "goodtag" },
    width: 270,
    align: "left",
  },

  {
    title: "招商人员",
     dataIndex: "merchant_user_profile_username",
     align: "center",
     width: 120,
   },
  {
    title: "最近更新时间",
    dataIndex: "update_time",
    align: "center",
    width: 160,
  },
  {
    title: "更新人员",
    dataIndex: "modified_by__username",
    align: "center",
    width: 150,
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 180,
    scopedSlots: { customRender: "operation" },
  },
];

export default {
  name: "GoodsList",
  components: {
    GoodsData,
    GoodsSearchBar,
    ImportFile,
    TimingOnDownModal,
    ImportResultDownloadModal,
    BatchTimingOnDownModal,
    BatchTimingOnDownResultModal,
  },
  data() {
    return {
      rules: {
        tag1: [ {
          validator: checkNotspecial,
        }],
        tag2: [ {
          validator: checkNotspecial,
        }],
      },
      tableScrollY: 600,
      form: this.$form.createForm(this),
      goodsTypeForm: this.$form.createForm(this),
      goodsColumns,
      sendSampleOptions,
      currentGoodsChannel: 1,
      createGoodsDrawerVisiable: false,
      showGoodsDrawerVisiable: false,
      timingOnDownDrawerVisiable: false,
      createGoodsLoading: false,
      chooseGoodsChannelVisible: false,
      listLoading: true,
      list: [],
      uploadHeaders: { Authorization: `jwt ${store.getters.getToken}` },
      pagination: {
        size: 'small',
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total) =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize),
      },
      // 新增商品弹层
      createGoodsDialogVisible: false,
      // 商品id是否正确
      isVaildGoodsId: true,
      // 商品id检查结果
      goodsIdInvaildMsg: "",
      // 批量上传商品弹层
      batchUploadGoodsDialogVisible: false,
      // 选中的数据
      selectedRows: [],
      selectedRowKeys: [],
      // 商品类目数据
      goodsCatesList: [],
      goodsForm: this.$form.createForm(this),
      // 临时商品id
      tempGoodsId: "",
      //  新建商品淘宝联盟信息
      taobaoUnionData: {},
      // 右侧drawer
      goodsDrawerTitle: "新建商品",
      // drawer状态
      drawerType: "CREATE",
      // 当前项商品信息
      currentGoodsData: {},
      // 查询条件
      searchParams: {
        search: this.$route.query.id || '',
        type: 2,
        is_support_send: undefined,
        is_on_sale: undefined,
        is_recycle: undefined,
        data_source: undefined,
        source: undefined,
        goods_channel: undefined,
        is_support_new: undefined,
        time_type: undefined,
        start_time: undefined,
        end_time: undefined,
        custom_cat: undefined,
        supplier_obj_id: undefined,
        merchant_user_profile_username: undefined,
        kuran_price: [],
        external_commission_rate: [],
        campaign_product_service_rate: [],
        item_tag: undefined
      },
      // 品牌列表
      goodsBrandList: [],
      // 供应商列表
      supplierList: [],
      // 是否显示批量定时上下架
      isBatchTimingOnDown: false,
      // 批量上下架id
      batchItems: [],
      // 批量上下架结果modal
      isBatchTimingOnDownResultShow: false,
      // 批量上下架结果
      batchResult: { fail_count: 0 },
      visibleTB: false,
      active_id: undefined,
      active_goods: undefined,
      active_tb_list: [],
      visibleImg: true,
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 335;
  },
  mounted() {
    this.getGoodsList();
    this.getSupplierList();
  },
  methods: {
    //提交信息
    validator(value){
      if (value === '' || value === undefined) {
        return true;
      } else {
        ////[^\u4E00-\u9FA5]/g
        ///[^\a-\z\A-\Z0-9\u4E00-\u9FA5\@\.]/g
        if (!(/^[\u4E00-\u9FA5A-Za-z0-9.]+$/.test(value))) {
          // this.$message.warning('只允许字母、数字、中文');
          return false;
        }else if(value.length===1||value.length>6) {
          // this.$message.warning('限制输入2-6位');
          return false;
        } else {
         return true;
        }
      }
    },
   async commitgood(record) {
     record.tag1 = record.tag1 || '';
     record.tag2 = record.tag2 || '';
     var istag1=this.validator(record.tag1);
     var istag2=this.validator(record.tag2);
     if(istag1&&istag2){
     if(record.tag1===record.tag2 && record.tag1!=='') {
       this.$message.warning('标签1与标签2不能一样！！！');
       return false;
     }
     var tag1=record.item_tag_list?record.item_tag_list[0]:'';
     var tag2=record.item_tag_list?record.item_tag_list[1]:'';
     if(record.tag1 === tag1 && record.tag2 === tag2){
         return ;
     }
     const token = this.$store.getters.getToken;
     const  Authorization=token ? token : undefined;
     var data={
       item_id: record.item_id,
       Authorization: Authorization,
       item_tag_list: [record.tag1||'',record.tag2||'']
     }
     const { err, res } = await editGoodsTagsIn(data);
     if (!err) {
       if (res.success) {
         record.item_tag_list=[record.tag1||'',record.tag2||''];
         this.$message.success("更新成功！");
       }
     }
     }
    },
    // 寄样模式
    sampleModeMethods(valCompare) {
      for (const val of this.sendSampleOptions) {
        if (val.value === valCompare) {
          return val
        }
      }
    },
    // 根据类型选择是否显示商品id确认框或者直接显示添加商品
    showGoodsNextDialog() {
      this.chooseGoodsChannelVisible = false;
      if (this.currentGoodsChannel === 1) {
        // 淘宝商品
        this.createGoodsDialogVisible = true;
        this.goodsForm.resetFields();
        this.currentGoodsData = {};
      } else {
        // 抖音小店
        this.createGoodsDrawerVisiable = true;
      }
    },
    // 新增商品
    handleShowCreateGoodsDialog() {
      this.drawerType = "CREATE";
      this.tempGoodsId = "";
      this.currentGoodsChannel = 1;
      this.chooseGoodsChannelVisible = true;
      this.goodsTypeForm.resetFields();
      // this.createGoodsDialogVisible = true;
      // this.goodsForm.resetFields();
      this.currentGoodsData = {};
    },
    // 批量新增商品
    handleShowBatchUploadGoodsDialog() {
      this.batchUploadGoodsDialogVisible = true;
      this.importStatus = "init";
    },
    // 检查商品id是否合法
    async handleCheckGoodsStatus() {
      this.goodsForm.validateFields(async (formErr) => {
        if (!formErr) {
          this.createGoodsLoading = true;
          const { err, res } = await getCheckKrGoods({
            item_id: this.tempGoodsId,
          });
          this.createGoodsLoading = false;
          if (!err) {
            if (res.success) {
              this.createGoodsDialogVisible = false;
              this.createGoodsDrawerVisiable = true;
              this.createGoodsLoading = false;
              this.drawerType = "CREATE";
              this.goodsDrawerTitle = "新增商品";
              this.taobaoUnionData = res.data;
            }
          }
        }
      });
    },
    // 获取商品类目
    async getGoodsCategorysData() {
      if(this.goodsCatesList.length>0){
        return ;
      }
      const params = {
        channel: 6,
      };
      const { err, res } = await getGoodsCategorys(params);
      if (!err && res.success) {
        this.goodsCatesList = res.data;
      }
    },
    // 更新商品ID
    handleChangeTempGoodsId(value) {
      this.tempGoodsId = value;
    },
    // 上传完成回调
    importFileCallback(e) {
      this.batchUploadGoodsDialogVisible = false;
      if (e) {
        this.pagination.current = 1;
        this.getGoodsList();
      }
    },
    handleOnePutDownGoods(row, is_on_sale) {
      const text = is_on_sale === 1 ? '上架' : '下架'
      this.$confirm({
        title: `商品${text}`,
        content: `确认${text}商品?`,
        onOk: async () => {
          const { err, res } = await patchGoodsStatus({
            item_ids: [row.item_id],
            is_on_sale
          });
          if (!err) {
            if (res.success) {
              this.$message.success("成功！");
            }
            this.getGoodsList();
          }
        }
      });
    },
    // 批量上架
    handleBatchPutawayGoods() {
      if (this.selectedRows.length === 0) {
        this.$message.error("请先选择数商品");
      } else {
        const that = this;
        this.$confirm({
          title: "商品上架",
          content: "确认上架选中商品?",
          async onOk() {
            const item_ids = that.selectedRows.map((i) => i.item_id);
            const { err, res } = await patchGoodsStatus({
              item_ids,
              is_on_sale: 1,
            });
            if (!err) {
              if (res.success) {
                that.selectedRows=[];
                that.selectedRowKeys=[];
                that.$message.success("成功！");
              }
              that.getGoodsList();
            }
          },
          onCancel() {
          },
        });
      }
    },
    // 批量下架
    handleBatchDownGoods() {
      if (this.selectedRows.length === 0) {
        this.$message.error("请先选择数商品");
      } else {
        const that = this;
        this.$confirm({
          title: "商品下架",
          content: "确认下架选中商品?",
          async onOk() {
            const item_ids = that.selectedRows.map((i) => i.item_id);
            const { err, res } = await patchGoodsStatus({
              item_ids,
              is_on_sale: 2,
            });
            that.getGoodsList();
            if (!err) {
              if (res.success) {
                that.$message.success("成功！");
              }
            }
          },
          onCancel() {
          },
        });
      }
    },

    // 批量定时上架/下架
    handleBatchTime(type) {
      if (this.selectedRows.length === 0) {
        this.$message.error("请先选择数商品");
      } else {
        this.batchItems = this.selectedRows.map((i) => i.id);
        this.isBatchTimingOnDown = true;
        this.currentBatchTimingOnDownType = type;
      }
    },
    // 隐藏批量定时上下架
    handleHideBatchTime() {
      this.isBatchTimingOnDown = false;
    },
    // 选中不选中行
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // 隐藏编辑drawer
    handleCreateGoodsDrawerClose(freshPage = -1) {
      this.createGoodsDrawerVisiable = false;
      if (freshPage === 0) {
        // 刷新当前页
        this.getGoodsList();
      } else if (freshPage === 1) {
        // 刷新第一页
        this.pagination.current = 1;
        this.getGoodsList();
      }
    },
    // 隐藏定时上下架 drawer
    handleTimingOnDownDrawerClose(freshPage = -1) {
      this.timingOnDownDrawerVisiable = false;
      if (freshPage === 0) {
        // 刷新当前页
        this.getGoodsList();
      } else if (freshPage === 1) {
        // 刷新第一页
        this.pagination.current = 1;
        this.getGoodsList();
      }
    },
    // 隐藏查看drawer
    handleShowGoodsDrawerClose() {
      this.showGoodsDrawerVisiable = false;
    },
    // 获取商品列表
    async getGoodsList() {
      if (!checkPer("goods_manage_goods_list")) {
        this.$message.error("无权限获取列表");
        return false;
      }

      const data = {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        search: this.searchParams.search,
        is_support_send: this.searchParams.is_support_send,
        is_recycle: this.searchParams.is_recycle,
        is_on_sale: this.searchParams.is_on_sale,
        data_source: this.searchParams.data_source,
        source: this.searchParams.source,
        goods_channel: this.searchParams.goods_channel,
        is_support_new: this.searchParams.is_support_new,
        time_type: this.searchParams.time_type,
        start_time: this.searchParams.start_time,
        end_time: this.searchParams.end_time,
        custom_cat: this.searchParams.custom_cat,
        supplier_obj_id: this.searchParams.supplier_obj_id,
        kuran_price_start: this.searchParams.kuran_price[0],
        kuran_price_end: this.searchParams.kuran_price[1],
        external_commission_rate_start: this.searchParams.external_commission_rate[0],
        external_commission_rate_end: this.searchParams.external_commission_rate[1],
        campaign_product_service_rate_start:this.searchParams.campaign_product_service_rate[0],
        campaign_product_service_rate_end: this.searchParams.campaign_product_service_rate[1],
        merchant_user_profile_username: this.searchParams.merchant_user_profile_username,
        item_tag: this.searchParams.item_tag,
      };
      if (data.external_commission_rate_start) {
        data.external_commission_rate_start = parseInt(data.external_commission_rate_start * 100.0);
      }
      if (data.external_commission_rate_end) {
        data.external_commission_rate_end =  parseInt(data.external_commission_rate_end * 100.0);
      }
      if (data.campaign_product_service_rate_start) {
        data.campaign_product_service_rate_start = parseInt(data.campaign_product_service_rate_start);
      }
      if (data.campaign_product_service_rate_end) {
        data.campaign_product_service_rate_end = parseInt(data.campaign_product_service_rate_end);
      }
      this.listLoading = true;
      const { err, res } = await getKrGoodsList(data);
      await this.getGoodsCategorysData();
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results.map(item=>{
            item.tag1=item.item_tag_list?item.item_tag_list[0]:'';
            item.tag2=item.item_tag_list?item.item_tag_list[1]:'';
            return item;
          });
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      }
      this.getGoodsBrandData();
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getGoodsList();
    },
    // 点击编辑按钮
    handleEditGoods(record) {
      this.currentGoodsChannel = record.goods_channel;
      this.drawerType = "EDIT";
      this.goodsDrawerTitle = "编辑商品";
      this.currentGoodsData = record;
      this.currentGoodsData.custom_cat = record.item_id__custom_cat;
      if (record.goods_channel === 1) {
        this.taobaoUnionData = record;
      }
      this.createGoodsDrawerVisiable = true;
    },
    // 点击定时上下架按钮
    handleTimingOnDown(record) {
      this.timingOnDownDrawerVisiable = true;
      this.currentGoodsData = record;
    },
    // 点击查看按钮
    handleShowGoods(record) {
      this.drawerType = "EDIT";
      this.goodsDrawerTitle = "编辑商品";
      this.currentGoodsData = record;
      this.taobaoUnionData = record;
      this.currentGoodsChannel = record.goods_channel;
      this.currentGoodsData.custom_cat = record.item_id__custom_cat;
      this.showGoodsDrawerVisiable = true;
    },
    // 查询
    searchBarCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.selectedRowKeys = [];
      this.selectedRows = [];
      this.getGoodsList();
    },
    // 点击批量导入结果下载弹窗
    async handleDownloadImportResultsClick() {
      this.$refs.importResultDownloadModal.showModal();
    },
    // 获取商品品牌数据
    async getGoodsBrandData() {
      if(this.goodsBrandList.length>0){
        return ;
      }
      const { err, res } = await brandList({ page_size: 1000 });
      if (!err && res.success) {
        this.goodsBrandList = res.data.results;
      }
    },

    // 获取商家数据
    async getSupplierList() {
      const { err, res } = await supplierList();
      if (!err && res.success) {
        this.supplierList = res.data.results
      }
    },

    // 批量上下架结果
    handleBatchResult(data) {
      if (data.fail_count) {
        this.batchResult = data;
        this.isBatchTimingOnDownResultShow = true;
      }
    },
    // 隐藏批量上下架结果
    hideBatchTimingOnDownResultModal() {
      this.isBatchTimingOnDownResultShow = false;
      this.getGoodsList();
    },
    // 批量上下架结果回调，显示商品详情
    handleBatchTimingOnDownResultCallback(id) {
      const record = this.list.find(i => i.id === id);
      this.handleShowGoods(record);
    },
    // 批量定时刷新页面数据
    refreshData() {
      this.getGoodsList();
    },
    // 导出
    async handleExport() {
      const parms = this.searchParams;
      this.listLoading = true;
      const { res } = await exportKrGoodsList(parms);
      this.listLoading = false;
      if (res.success) {
        this.$message.success('导出成功');
        location.href = res.data.detail_url;
      }
    },
    handleSearch(search) {
      searchActiveList({ search, enrolling: 1 }).then((res) => {
        if (res.res.success) {
          this.active_tb_list = res.res.data.results
        } else {
          this.active_tb_list = []
        }
      })
    },
    activeOk() {
      if (!this.active_id) {
        message.error('请选择提报活动')
        return
      }
      const params = {
        item_id: this.active_goods.id,
        item__external_commission_rate: this.active_goods.external_commission_rate,
        campaign_id: this.active_id
      }
      relationActive(params).then((res) => {
        if (res.res.success) {
          this.visibleTB = false
          message.success('提报成功')
        }
      })
    },
    handleOpenActive(row) {
      this.active_id = undefined
      this.active_goods = row
      this.visibleTB = true
    }
  },
};
</script>
<style lang='less' scoped>
  #specialManage {
    .ptb-10 {
      padding: 10px 0;
    }
    /deep/ .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-table-body {
      //样式穿透
      overflow-x: auto !important;
    }
    .ant-form-vertical .ant-form-explain {
      font-size: 12px;
    }
    /deep/ .ant-form-vertical .ant-form-explain {
      font-size: 12px;
    }
  }
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;

  .option-btn {
    margin-right: 10px;
  }
}

.upload-res-box {
  line-height: 150%;
}

.upload-spin {
  text-align: center;
  padding: 30px 50px;
}

.p-20 {
  padding: 20px;
}

.mr-10 {
  margin-right: 10px;
}

.dialog-active-tb {
  .ant-select {
    width: 100%;
  }
}
  .el-img{
    width: 54px;
    height: 54px;
    /deep/ .el-image__inner {
      width: 54px;
      height: 54px;
    }
    .el-image__inner {
      width: 54px;
      height: 54px;
    }
  }
  /*.tab-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .tablecl {
    flex-grow: 1;
  }*/
</style>
