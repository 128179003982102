<!--
 * @Description: 导入结果下载弹窗
 * @Date: 2020-05-11 13:43:24
 * @LastEditTime: 2020-06-05 09:41:56
 * @LastEditors: 前胡
 -->
<template>
  <a-modal
    class="import-results-download-modal"
    title="批量导入结果下载"
    v-model="importResultDownloadModalVisiable"
    centered
    :bodyStyle="{padding:0}"
    :footer="null"
    :width="800"
    @cancel="hideModal"
  >
    <a-spin :spinning="loading">
      <div
        v-infinite-scroll="handleInfiniteOnLoad"
        class="import-result-infinite-container"
        :infinite-scroll-disabled="finished"
        :infinite-scroll-distance="10"
      >
        <a-list :data-source="data">
          <a-list-item slot="renderItem" slot-scope="item, index">
            <a-list-item-meta :key="index">
              <a class="download-link" slot="title" :href="item.download_url" target="_blank">{{item.file_name}}</a>
            </a-list-item-meta>
            <div class="mr-10">
              <template v-if="item.status===2"><a-tag color="green">处理成功</a-tag></template>
              <template v-if="item.status===3"><a-tag color="red">处理失败</a-tag></template>
            </div>
            <div>{{item.create_time}} {{item.username}}</div>
          </a-list-item>
        </a-list>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import infiniteScroll from 'vue-infinite-scroll';
import { getImportResultList } from '@/service/goods';
export default {
  directives: { infiniteScroll },
  name: 'ImportResultDownloadModal',
  data() {
    return {
      importResultDownloadModalVisiable: false,
      data: [],
      page: 1,
      loading: false,
      finished: false
    }
  },
  mounted() {
    this.getImportResultList();
  },
  methods: {
    // 关闭弹窗
    hideModal() {
      this.importResultDownloadModalVisiable = false;
    },
    // 打开弹窗
    showModal() {
      this.importResultDownloadModalVisiable = true;
      this.getImportResultList();
    },
    // 获取批量导入结果列表数据
    async getImportResultList() {
      this.loading = true
      const { err, res } = await getImportResultList({ page: this.page, page_size: 10 });
      if (!err && res.success) {
        this.data = this.page === 1 ? res.data.results : this.data.concat(res.data.results);
        if (!res.data.next) this.finished = true;
      }
      this.loading = false
    },
    // 触发无限加载
    handleInfiniteOnLoad () {
      if (this.finished || this.loading) return;
      this.page += 1;
      this.getImportResultList();
    }
  }
}
</script>

<style lang='less' scoped>
  @import '../../../assets/less/var.less';
  .import-result-infinite-container {
    height: 500px;
    overflow: auto;
    padding: 0 24px;
  }
  .download-link {
    color: @red-color;
  }
</style>