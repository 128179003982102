<!--
 * @Description: 定时上下架
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-04-23 17:58:45
 -->
 <template>
  <div class="timing-on-down-modal">
    <a-modal title="定时上下架" v-model="timingOnDownModalVisible" @cancel="handleHideThisModal">
      <a-form :form="editTimingOnForm">
        <a-form-item :label-col="{ span: 1 }" :wrapper-col="{ span: 12 }">
          <a-checkbox v-model="is_start_enable" :disabled="data.is_on_sale===1">定时上架</a-checkbox>
          <div v-if="is_start_enable">
            <a-date-picker
              format="YYYY-MM-DD HH:mm"
              @change="onChangeStartDate"
              :minuteStep="15"
              :showToday="false"
              :showTime="showTimeOption"
              :defaultValue="default_sale_start_time"
              v-decorator="[
          'sale_start_time',
          { rules: [{ required: true, message: '请设置定时上架时间' }] },
        ]"
            />
          </div>
        </a-form-item>
        <a-form-item :label-col="{ span: 1 }" :wrapper-col="{ span: 12 }">
          <a-checkbox v-model="is_end_enable">定时下架</a-checkbox>
          <div v-if="is_end_enable">
            <a-date-picker
              format="YYYY-MM-DD HH:mm"
              @change="onChangeEndDate"
              :minuteStep="15"
              :showToday="false"
              :showTime="showTimeOption"
              :defaultValue="default_sale_end_time"
              v-decorator="[
          'sale_end_time',
          { rules: [{ required: true, message: '请设置定时下架时间' }] },
        ]"
            />
          </div>
        </a-form-item>
      </a-form>
      <template slot="footer">
        <a-button key="back" @click="handleHideThisModal">取消</a-button>
        <a-button
          key="submit"
          type="primary"
          :loading="isPosting"
          @click="handleSaveTimingOnDownData"
        >提交</a-button>
      </template>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import { patchTimingOnSale } from "@/service/goods";
export default {
  name: "TimingOnDownModal",
  props: {
    data: {
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      editTimingOnForm: this.$form.createForm(this),
      timingOnDownModalVisible: true,
      showTimeOption: {
        format: "HH:mm",
        minuteStep: 15,
        defaultValue: moment("00:00", "HH:mm")
      },
      isPosting: false,
      sale_start_time: this.data.sale_start_time ? moment(new Date(this.data.sale_start_time)).format("YYYY-MM-DD HH:mm") : undefined,
      sale_end_time: this.data.sale_end_time ? moment(new Date(this.data.sale_end_time)).format("YYYY-MM-DD HH:mm") : undefined,
      is_start_enable: this.data.is_on_sale === 2,
      is_end_enable: true,
      default_sale_start_time: this.data.sale_start_time
        ? moment(this.data.sale_start_time, "YYYY-MM-DD HH:mm")
        : undefined,
      default_sale_end_time: this.data.sale_end_time
        ? moment(this.data.sale_end_time, "YYYY-MM-DD HH:mm")
        : undefined
    };
  },
  mounted() {
    this.$nextTick(() => {
      if (this.sale_start_time) {
        this.editTimingOnForm.setFieldsValue({
          sale_start_time: this.sale_start_time
        });
      }
      if (this.sale_end_time) {
        this.editTimingOnForm.setFieldsValue({
          sale_end_time: this.sale_end_time
        });
      }
    });
  },
  methods: {
    moment,
    // 修改定时上架
    onChangeStartDate(date, dateString) {
      this.sale_start_time = dateString;
    },
    // 修改定时下架
    onChangeEndDate(date, dateString) {
      this.sale_end_time = dateString;
    },
    // 提交数据
    handleSaveTimingOnDownData() {
      this.editTimingOnForm.validateFields(async err => {
        if (!err) {

          
          let data = {
            sale_start_time: this.sale_start_time
              ? this.sale_start_time
              : null,
            sale_end_time: this.sale_end_time ? this.sale_end_time : null
          };

          if(!this.is_start_enable){
            data.sale_start_time = null
          }

          if(!this.is_end_enable){
            data.sale_end_time = null
          }

          // if (!data.sale_start_time && !data.sale_end_time) {
          //   this.$message.error("请至少选择一个时间!");
          //   return false;
          // }

          this.isPosting = true;
          const { res_err, res } = await patchTimingOnSale(this.data.id, data);
          this.isPosting = false;

          if (!res_err) {
            if (res.success) {
              this.$message.success(res.message);
              this.handleHideThisModal(0);
            }
          }
        }
      });
    },
    // 隐藏modal
    handleHideThisModal(isNeedParent = -1) {
      this.$emit("callback", isNeedParent);
    }
  }
};
</script>
<style lang="less" scoped>
.user-other-address-modal {
  width: 800px;
}
.address-list {
  margin: 0;
  padding: 0;
  list-style: none;
  .address-item {
    margin-bottom: 10px;
    .ai-name {
      width: 150px;
      display: inline-block;
    }
    .ai-phone {
      width: 120px;
      display: inline-block;
    }
    .ai-address {
    }
  }
}
</style>