<!--
 * @Description: 定时上下架
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-07-18 13:57:47
 -->
 <template>
  <div class="timing-on-down-modal">
    <a-modal title="操作结果" :footer="null" :width="800" v-model="timingOnDownModalVisible" @cancel="handleHideThisModal">
      <div class="res-tip"><strong class="success">{{batchResult.success_count}}</strong> 件商品设置成功，<strong class="error">{{batchResult.fail_count}}</strong> 件商品设置失败</div>
      <div class="res-box">
        <table class="fail-table">
          <thead>
            <tr>
              <th width="80">商品图片</th>
              <th width="200">商品名称</th>
              <th>失败原因</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in batchResult.fail_list" :key="i.id">
              <td style="text-align:center;"><img :src="i.pict_url" alt="" style="max-width:54px;max-height:54px;"></td>
              <td><a href="javascript:;" @click="openGoodsDetail(i.id)">{{i.item_name}}</a></td>
              <td>{{i.error_msg}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "BatchTimingOnDownResultModal",
  props: {
    batchResult: {
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      timingOnDownModalVisible: true
    };
  },
  methods: {
    openGoodsDetail(id){
      this.$emit('callback', id)
    },
    handleHideThisModal(){
      this.$emit('hidemodal')
    }
  }
};
</script>
<style lang="less" scoped>
.res-tip {
  margin-bottom: 15px;
  .success {
    color: green;
  }
  .error {
    color: red;
  }
}
.res-box {
  max-height: 400px;
  overflow-y: auto;
}
.fail-table{
  width: 100%;
  th,td {
    padding: 2px 4px;
    border: 1px solid #eee;
  }
  th {
    text-align: center;
    background-color: #f5f5f5;
  }
  tbody {}
}
</style>
