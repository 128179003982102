<!--
 * @Description: 规格设置弹层
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-12 17:03:34
 * @LastEditTime: 2020-07-16 14:51:35
 -->
 <template>
  <div>
    <a-modal
      title="规格设置"
      v-model="isShowModal"
      centered
      :bodyStyle="{padding:0}"
      @cancel="hideISpecPickerModal"
      @ok="hideISpecPickerModalWithData"
      :width="1000"
    >
      <div class="spec-picker-box">
        <a-radio-group v-model="main_spec">
          <a-checkbox-group v-model="sub_specs">
            <table class="spec-picker-table">
              <tbody>
                <tr>
                  <th width="90">是否主规格</th>
                  <th width="90">是否副规格</th>
                  <th width="100">规格</th>
                  <th width="650">规格值</th>
                </tr>
                <tr v-for="i in spec_data" :key="i.name">
                  <td width="90" class="tac">
                    <a-radio :value="i.name"></a-radio>
                  </td>
                  <td width="90" class="tac">
                    <a-checkbox
                      :value="i.name"
                      v-if="main_spec !== i.name && ((sub_specs.includes(i.name) || sub_specsLength))"
                    ></a-checkbox>
                  </td>
                  <td width="100">{{i.name}}</td>
                  <td>
                    <a-checkbox-group
                      v-if="main_spec === i.name || sub_specs.includes(i.name)"
                      :options="i.value_list"
                      v-model="spec_value[i.name]"
                    ></a-checkbox-group>
                  </td>
                </tr>
              </tbody>
            </table>
          </a-checkbox-group>
        </a-radio-group>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Vue from 'vue';
import { getSpecs } from "@/service/operate.js";
import { skuListGen } from "@/utils/index.js";

export default {
  name: "SpecPickerModal",
  props: {
    fillData: {
      type: Object,
      default: function() {
        return {
          spec_data: [], // 原始规则数据
          main_spec: undefined, // 主规格
          sub_specs: [], // 副规格
          spec_value: {} // 规格值
        };
      }
    }
  },
  data() {
    const _fillData = JSON.parse(JSON.stringify(this.fillData));
    return {
      isShowModal: true,
      spec_data: _fillData.spec_data, // 原始规则数据
      main_spec: _fillData.main_spec, // 主规格
      sub_specs: _fillData.sub_specs, // 副规格
      spec_value: _fillData.spec_value // 规格值
    };
  },
  computed: {
    sub_specsLength() {
      return this.sub_specs.length < 2;
    }
  },
  watch: {
    // 主规格改变监控
    main_spec(v, ov) {
      if (this.sub_specs.includes(v)) {
        const _idx = this.sub_specs.indexOf(v);
        this.sub_specs.splice(_idx, 1);
      }
      Vue.delete(this.spec_value, ov); // 删除老的主规格
    },
    // 副规格改变监控
    sub_specs(v){
      const currentSubSpecs = [this.main_spec, ...v];
      for (const key in this.spec_value) {
        if (this.spec_value.hasOwnProperty(key)) {
          if(!currentSubSpecs.includes(key)){
            Vue.delete(this.spec_value, key);
          }
        }
      }
    }
  },
  mounted() {
    this.getSpecData();
  },
  methods: {
    // 获取规格
    async getSpecData() {
      const { err, res } = await getSpecs({ page_size: 1000 });
      if (!err && res.success) {
        const spec_data = JSON.parse(JSON.stringify(res.data.results));
        spec_data.forEach(i => {
          i.value_list.forEach(j => {
            j.label = j.value;
            j.ov = j.value;
            j.value = j.id;
          });
        });
        this.spec_data = spec_data;
      }
    },
    // 隐藏弹层
    hideISpecPickerModalWithData() {
      const sub_body_id = [];
      for (const key in this.spec_value) {
        if (this.spec_value.hasOwnProperty(key)) {
          const ids = this.spec_value[key];
          if (key !== this.main_spec) {
            sub_body_id.push(ids);
          }
        }
      }

      const data = {
        main_spec: this.main_spec,
        sub_specs: this.sub_specs,
        spec_value: this.spec_value,
        id_map: this.specDataToObject(),
        sku_list: []
      };

      // 主规格是否选择
      if (!data.main_spec) {
        this.$message.error("请选择主规格!");
        return false;
      }
      // 主规格规格值是否选择
      if (
        !data.spec_value.hasOwnProperty(data.main_spec) ||
        !data.spec_value[data.main_spec].length
      ) {
        this.$message.error("请选择主规格对应的规格值!");
        return false;
      }
      // 副规格对应的规格值是否选择
      if (data.sub_specs.length) {
        const rsx = data.sub_specs.every(
          i => data.spec_value.hasOwnProperty(i) && data.spec_value[i].length
        );
        if (!rsx) {
          this.$message.error("请选择副规格对应的规格值!");
          return false;
        }
      }

      const _body_id = [this.spec_value[this.main_spec], ...sub_body_id];
      const _sku_list = sub_body_id.length  // 多维
        ? skuListGen(JSON.parse(JSON.stringify(_body_id))).map(i => {
            return {
              main_spec: i[0],
              sub_spec1: i[1] || undefined,
              sub_spec2: i[2] || undefined,
              kuran_price: undefined,
              final_price: undefined
            };
          })
        : this.spec_value[this.main_spec].map(i => ({ // 只有主规格
            main_spec: i,
            sub_spec1: undefined,
            sub_spec2: undefined,
            kuran_price: undefined,
            final_price: undefined
          }));
      data.sku_list = _sku_list;
      this.$emit("callback", data);
    },
    // 只隐藏
    hideISpecPickerModal() {
      this.$emit("callback", null);
    },
    // 数组转对象
    specDataToObject() {
      const obj = {};
      this.spec_data.forEach(i => {
        i.value_list.forEach(j => {
          if (j.id) obj[j.id] = j.ov;
        });
      });
      return obj;
    }
  }
};
</script>
<style lang="less" scoped>
.spec-picker-box {
  padding: 10px;
  .spec-picker-table {
    width: 100%;
    th,
    td {
      border: 1px solid #ccc;
      padding: 4px;
      font-weight: normal;
    }
    th {
      background: #f5f5f5;
    }
  }
  .tac {
    text-align: center;
  }
  .gv-list {
    li {
      display: inline-block;
      margin-right: 15px;
      margin-bottom: 5px;
    }
  }
}
</style>
